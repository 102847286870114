import * as cognito from './cognito'
import {
  API,
  IUserEntityAssignment,
  IUserResponse,
  IUpdateUserEntityAssignment,
  setCognitoUser,
  setCognitoUserSession,
  setStoreAuthTokenFunction,
  setInterceptor,
} from '@getgreenline/homi-shared'
import {
  setCognitoUser as setSharedCognitoUser,
  setCognitoUserSession as setSharedCognitoUserSession,
  setStoreAuthTokenFunction as setSharedStoreAuthTokenFunction,
  setInterceptor as setSharedInterceptor,
  axios,
} from '@getgreenline/shared'
import { LocalStorage } from './LocalStorage'
import { setRootAuthToken } from './environment'
import { uniq } from 'lodash'
import endpoints from '../constants/Endpoints'

export { resendInvite, getUserStatus } from '@getgreenline/authn'
/**
 * Get all the companies this user has access to.
 */
export const getAllUserCompanies = async (assignments: IUserEntityAssignment[]) => {
  const assigned = assignments.filter((assignment) => assignment.type === 'assigned')
  const companies = assigned.filter((entity) => entity.entity.entityType === 'company')
  const locations = assigned.filter((entity) => entity.entity.entityType === 'location')
  const locationCompanyIds = locations.map((entity) => entity.entity.rootEntityId)
  const uniqueCompanyIds = uniq(locationCompanyIds)
  for (const companyId of uniqueCompanyIds) {
    const locationEntityAssignment = locations.find(
      (location) => location.entity.rootEntityId === companyId,
    )
    if (!locationEntityAssignment) continue

    const company = await API.getCompanyById(companyId, false)
    const entity = {
      ...locationEntityAssignment,
      entity: {
        ...locationEntityAssignment.entity,
        name: company.name,
      },
    }
    companies.push(entity)
  }

  return companies.map((assignment) => {
    return {
      ...assignment,
      entity: {
        ...assignment.entity,
        id: assignment.entity.rootEntityId,
      },
    }
  })
}

export const loginV2 = async (
  email: string,
  password: string,
): Promise<IUserResponse | undefined> => {
  try {
    const { cognitoAccessToken } = await authenticateCognitoUser(email, password)

    // set to cognitoAuthToken
    setRootAuthToken(cognitoAccessToken)

    await LocalStorage.setAuthToken(cognitoAccessToken)
    setRootAuthToken(cognitoAccessToken)
    return await API.getMe()
  } catch (err) {
    const { cognitoAccessToken } = await authenticateCognitoUser(email, password)

    setRootAuthToken(cognitoAccessToken)

    return await API.getMe()
  }
}

export const getCognitoUser = cognito.getCurrentUser

export const getTokenV2 = async () => {
  const cognitoUser = cognito.getCurrentUser()
  if (!cognitoUser) return
  const cognitoUserSession = await cognito.getCognitoUserSession(cognitoUser)
  const authToken = cognitoUserSession.getAccessToken().getJwtToken()
  return authToken
}

export const authenticateCognitoUser = async (email: string, password: string) => {
  try {
    const cognitoUser = cognito.createUser(email)
    const authenticationDetails = cognito.getAuthenticationDetails(email, password)
    const cognitoUserSession = await cognito.authenticateUser(cognitoUser, authenticationDetails)
    const cognitoAccessToken = cognitoUserSession.getAccessToken().getJwtToken()

    LocalStorage.setAuthToken(cognitoAccessToken)
    return { cognitoAccessToken, cognitoUserSession }
  } catch (err) {
    console.warn(err)
    throw Error('Invalid credentials')
  }
}

export const forgotPasswordV2 = (email: string) => {
  const cognitoUser = cognito.createUser(email)
  return cognito.sendForgotPasswordEmail(cognitoUser)
}

export const resetPasswordV2 = ({
  email,
  verificationCode,
  newPassword,
}: {
  email: string
  verificationCode: string
  newPassword: string
}) => {
  const cognitoUser = cognito.createUser(email)
  return cognito.resetPassword({ cognitoUser, verificationCode, newPassword })
}

export const confirmSignup = async ({
  email,
  verificationCode,
  newPassword,
}: {
  email: string
  verificationCode: string
  newPassword: string
}) => {
  const cognitoUser = cognito.createUser(email)
  const authenticationDetails = cognito.getAuthenticationDetails(email, verificationCode)
  return cognito.authenticateUser(cognitoUser, authenticationDetails, newPassword)
}

export interface IUserMigratePasswordContract {
  email: string
  oldPassword: string
  newPassword: string
}

export const resetMigrationPassword = async (migrateUser: IUserMigratePasswordContract) => {
  const migrateUserResponse = await axios.post<Record<never, never>>(
    endpoints.MIGRATE_USER_PASSWORD,
    migrateUser,
  )
  return migrateUserResponse.data
}

export const isPasswordStrongEnough = (password: string) => {
  const passwordRegex = new RegExp('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})')
  const isStrongPassword = passwordRegex.exec(password)
  return !!isStrongPassword
}

const setGlobalAuthToken = async (authToken: string) => {
  setRootAuthToken(authToken)
  await LocalStorage.setAuthToken(authToken)
}

export const setupInterceptor = async () => {
  const cognitoUser = cognito.getCurrentUser()
  if (!cognitoUser) return
  const cognitoUserSession = await cognito.getCognitoUserSession(cognitoUser)
  const authToken = cognitoUserSession.getAccessToken().getJwtToken()
  await setGlobalAuthToken(authToken)
  setCognitoUser(cognitoUser)
  setSharedCognitoUser(cognitoUser)
  setCognitoUserSession(cognitoUserSession)
  setSharedCognitoUserSession(cognitoUserSession)
  setStoreAuthTokenFunction(setGlobalAuthToken)
  setSharedStoreAuthTokenFunction(setGlobalAuthToken)
  setInterceptor()
  setSharedInterceptor()
}

export const signOut = () => {
  const cognitoUser = cognito.getCurrentUser()
  cognitoUser?.signOut()
}
