import { observable, action, computed, runInAction } from 'mobx'
import {
  API,
  axios,
  ICompany,
  IUpdateCompany,
  IUserResponse,
  IUpdateUserRequest,
  ICreateUserRequest,
  IEntityUser,
  IDevice,
  SecurityRole,
  CreateSecurityRole,
  UpdateSecurityRole,
  IPayment,
  PaymentFilter,
  IBaseLoyaltyTier,
  IAddOrEditLoyaltyTier,
  CreateOrUpdateTransfer,
  ICreateCustomer,
  ITransferSearchFilter,
  IUpdateBulkInventory,
  ICompanyContract,
  GiftCardType,
  IGiftCardContract,
  IPOSListing,
  Pagination,
  ICompanyWithMetaDataContract,
  MetaKeys,
  CreatePayment,
} from '@getgreenline/homi-shared'
import { TagModels } from '@getgreenline//tags'
import { ProductStore } from './ProductStore'
import { SupplierStore } from './SupplierStore'
import Endpoints from '../../constants/Endpoints'
import { LocalStorage } from '../../utilities/LocalStorage'
import { FeatureToggleContract } from '@getgreenline/feature-toggle/build/models'
import { FeatureToggle } from '../../constants/FeatureToggles'
import { LocationModels, LocationApi } from '@getgreenline/locations'
import { MetaDataType } from '@getgreenline/shared'
import * as OCS from '@getgreenline/ocs'
import { isNil, startCase } from 'lodash'
import { getHashKeys, ICreateHashKeyRequestContract, ITenantPathParams } from '@getgreenline/ocs'
import { TransfersApi } from '@getgreenline/transfers'
import { ReceiptApi, ReceiptModels, ReceiptUtils } from '@getgreenline/receipts'
import { ExternalLoyaltyAPI, ExternalLoyaltyModels } from '@getgreenline/external-loyalty'
import { discountApi, DiscountModels } from '@getgreenline/products'
import { DiscountsPermissions } from '../../utilities/DiscountHelpers'
import { ShiftApi, ShiftModels } from '@getgreenline/shifts'
import { LoyaltyDiscountUtils } from '@getgreenline/loyalties'
import { ILoyaltyData } from '../../utilities/loyaltyDiscountHelpers'
import { IGlobalProductResponse } from '@getgreenline/global-products'

export interface ISignedImageUploadContract {
  apiKey: string
  timestamp: string
  url: string
  signature: string
}

export class CurrentCompanyStore {
  @observable productStore?: ProductStore
  @observable supplierStore?: SupplierStore
  @observable company?: ICompany
  @observable summary?: any
  @observable locations?: LocationModels.ILocationContract[]
  @observable globalLocations?: LocationModels.IGlobalLocationContract[]
  @observable globalHashKeys?: ICreateHashKeyRequestContract[]
  @observable companyUser?: IEntityUser
  @observable users?: IUserResponse[]
  @observable securityRoles?: SecurityRole[]
  @observable devices?: IDevice[]
  @observable loyaltyTiers?: IBaseLoyaltyTier[]
  @observable sortedLoyaltyTiers?: IBaseLoyaltyTier[]
  @observable mappedGiftCards: Map<string, IGiftCardContract> = new Map()
  @observable tags: TagModels.ITagContract[] = []
  @observable isWeedmapsEnabled = false
  @observable canUseWorldpay = false
  @observable canUseCannabinoidPerBatch?: boolean
  @observable canViewEcommerceSettings = false
  @observable canViewDigitalSignage: boolean | null = null
  @observable canUseInsightsQuicksight = false
  @observable canUseBroadcasts = false
  @observable isInsightsNavbarDisplayed = false
  @observable productDetailsPackageDate = false
  @observable productDetailsTerpenesAndCannabinoids = false
  @observable ircc = false
  @observable showBundledDiscounts = false
  @observable posListing?: IPOSListing[]
  @observable posListingFetchTime?: string | Date
  @observable loyaltyPointsMultiplier = 100
  @observable canUseNewMBComplianceReport?: boolean
  @observable canUseOCSCompliance = false
  @observable canUseCustomReceipt = false
  @observable canDisplayLoyaltyInfoOnReceipt = false
  @observable canUseBulkProductUpdate = false
  @observable canUseGlobalLogin = false
  @observable customReceiptSettings = observable.map<number, ReceiptModels.IReceiptContract>()
  @observable canUseLoyaltyService = false
  @observable canUseExternalLoyalty = false
  @observable externalLoyaltyProvider: ExternalLoyaltyModels.ExternalLoyaltyProviders | null = null
  @observable externalLoyaltyProviderConfig: ExternalLoyaltyModels.IExternalLoyaltyConfig | null =
    null
  @observable shouldDisplayInternalLoyaltyData = false
  @observable canUseHubspotChatbot = false
  @observable canUseCustomerSearchV2: boolean | undefined = undefined
  @observable canDisplayOnlineOrdersReceipts: boolean | undefined = undefined
  @observable canUsePlatformCustomers: boolean | undefined = undefined
  @observable canUsePlatformCustomersOutreach: boolean | undefined = undefined
  @observable canUseDiscountCode = false
  @observable canUseSalesChannel: boolean | undefined = undefined
  @observable salesChannels: DiscountModels.ISalesChannel[] = []
  @observable canUseAutoLoyaltyDiscount: boolean | undefined = undefined
  @observable canViewInventorySnapshots: boolean | undefined = undefined
  @observable canUseGlobalCatalog = false

  @action
  getById<T extends boolean>(companyId: number, includeMetaData?: T): Promise<ICompanyContract<T>> {
    return API.getCompanyById(companyId, !!includeMetaData as T).then((company) => {
      this.productStore = new ProductStore(company)
      this.supplierStore! = new SupplierStore(company)
      // Sub-stores must be initialized before the observable company is set, otherwise components will try to call sub-store methods when the company does not exist yet
      this.company = company

      return company
    })
  }

  @action
  async reloadCompany<T extends boolean>(includeMetaData?: T): Promise<void> {
    if (this.company) {
      const company = await API.getCompanyById(this.company.id, includeMetaData as T)
      this.company = company
    }
  }

  // Light-weight location array with all available locations
  @action
  getGlobalLocations = async () => {
    const param = new Pagination({
      offset: 0,
      limit: 100,
    })

    while (true) {
      const { data } = await LocationApi.getGlobalLocations(this.company!.id, param)

      this.globalLocations = [...(this.globalLocations || []), ...data]

      // If what is returned is less than the limit, that means there is no more location to fetch
      if (data.length < param.limit) break

      param.offset += param.limit
    }
  }

  @action
  getGlobalHashKeys = async () => {
    const tenantPathParams: ITenantPathParams = {
      gateway: Endpoints.AUTHZ_GATEWAY_URL,
      tenantId: `${this?.company?.id}`,
    }
    const { items: hashKeys } = await getHashKeys(tenantPathParams)
    this.globalHashKeys = hashKeys
  }

  @action
  getLocations = async (): Promise<LocationModels.ILocationContract[]> => {
    if (!this.company) {
      return new Promise((resolve, reject) => {
        resolve([] as LocationModels.ILocationContract[])
      })
    }

    const data = await LocationApi.getLocations(this.company.id, false)
    this.locations = data
    return data
  }

  @action
  getLocationsWithMetaData = async (): Promise<
    LocationModels.ILocationContractWithMetaData<MetaDataType>[]
  > => {
    if (!this.company) {
      return new Promise((resolve, reject) => {
        resolve([] as LocationModels.ILocationContractWithMetaData<MetaDataType>[])
      })
    }

    const data = await LocationApi.getLocations(this.company.id, true)
    this.locations = data
    return data
  }

  @action
  getTags = async () => {
    if (this.company) {
      const res = await axios.get(Endpoints.GET_COMPANY_TAGS(this.company.id))
      this.tags = res.data
      return this.tags
    }
  }

  @action
  addLocation = async (locationObject: any) => {
    const res = await LocationApi.addLocation(this.company!.id, locationObject)
    this.getLocations()
    return res
  }

  @action
  editLocation = async (locationObject: any): Promise<LocationModels.ILocationContract> => {
    await LocationApi.updateLocation(this.company!.id, locationObject)
    await this.getLocations()
    return locationObject
  }

  @action
  reorderLocations(order: Array<{ locationId: number; index: number }>) {
    return API.reorderLocations(this.company!.id, order).then(() => {
      this.getLocations()
    })
  }

  @action
  deleteLocation = async (locationId: number) => {
    const res = await LocationApi.deleteLocation(this.company!.id, locationId)
    this.getLocations()
    return res
  }

  @action
  update(updateObject: IUpdateCompany) {
    return API.updateCompany(this.company!.id, updateObject).then((company) => {
      this.company = company
      return company
    })
  }

  @action
  async refreshBirchmountConfigs(companyId: number, locationId: number) {
    await LocalStorage.setBirchmountApiKey(companyId)
    await LocalStorage.setBirchmountLocId(companyId, locationId)
  }

  @action
  async getGiftCardContracts() {
    const giftCardContracts = await API.getGiftCardsByCompany(this.company!.id)

    giftCardContracts.forEach((gc) => {
      if (gc.productId !== undefined) {
        this.mappedGiftCards.set(gc.productId!, gc)
      }
    })

    return giftCardContracts
  }

  @action
  updateGiftCard(
    giftCardId: GiftCardType,
    giftCardContract: IGiftCardContract,
  ): Promise<IGiftCardContract> {
    return API.updateGiftCard(this.company!.id, giftCardId, giftCardContract)
  }

  @action
  getEntityUsers() {
    return API.getCompanyUsers(this.company!.id).then((users) => {
      this.users = users
      return users
    })
  }

  @action
  getDevices() {
    return API.getDevices(this.company!.id).then((devices) => {
      this.devices = devices
      return devices
    })
  }

  getEntityUserByUserId(userId: string) {
    return API.getCompanyUserById(this.company!.id, userId).then((user) => {
      return user
    })
  }

  @action
  addEntityUser(createUser: ICreateUserRequest) {
    return API.addCompanyUser(this.company!.id, createUser).then((user) => {
      this.getEntityUsers()
      return user
    })
  }

  @action
  editEntityUser(userId: string, updateUser: IUpdateUserRequest) {
    return API.editCompanyUser(this.company!.id, userId, updateUser).then((user) => {
      this.getEntityUsers()
      return user
    })
  }

  @action
  deleteEntityUser(userId: string) {
    return API.deleteCompanyUser(this.company!.id, userId).then(() => {
      return this.getEntityUsers()
    })
  }

  @action
  getSecurityRoles() {
    return API.getSecurityRoles(this.company!.id).then((securityRoles) => {
      this.securityRoles = securityRoles
      return securityRoles
    })
  }

  @action
  addSecurityRole(createObject: CreateSecurityRole) {
    return API.addSecurityRole(this.company!.id, createObject).then((securityRole) => {
      this.getSecurityRoles()
      return securityRole
    })
  }

  @action
  updateSecurityRole(updateObject: UpdateSecurityRole) {
    return API.updateSecurityRole(this.company!.id, updateObject.id, updateObject).then(
      (securityRole) => {
        this.getSecurityRoles()
        return securityRole
      },
    )
  }

  @action
  editDeviceCashAmount(userId: string, deviceCashAmount: number, note: string) {
    return axios
      .put(Endpoints.EDIT_DEVICE(this.company!.id, userId), {
        id: userId,
        deviceCashAmount: deviceCashAmount,
        note: note,
      })
      .then((response) => {
        this.getDevices()
        const entityUser = response.data as IUserResponse
        return entityUser
      })
  }

  @action
  resetDevicePassword(userId: string, password: string) {
    return axios
      .put(Endpoints.EDIT_DEVICE(this.company!.id, userId), {
        id: userId,
        password: password,
      })
      .then((response) => {
        this.getDevices()
        const entityUser = response.data as IUserResponse
        return entityUser
      })
  }

  getCustomerWithHistoryById(customerId: string) {
    return API.getCustomerById(this.company!.id, customerId)
  }

  getCustomerHistoricalPayments(customerId: string) {
    return axios
      .get(Endpoints.GET_CUSTOMER_HISTORICAL_PAYMENTS(this.company!.id, customerId))
      .then((response) => {
        const payments = response.data.payments as IPayment[]
        return payments
      })
  }

  addCustomer(createObject: ICreateCustomer) {
    return API.createCustomer(this.company!.id, createObject)
  }

  editCustomer(customerId: string, updateObject: ICreateCustomer) {
    return API.updateCustomer(this.company!.id, customerId, updateObject)
  }

  getLocationPayments(locationId: number, filter: PaymentFilter) {
    return API.getPaymentsAtLocation(this.company!.id, locationId, filter).then((response) => {
      const paginatedPayment = response
      const paginatedPayments = {
        payments: paginatedPayment.payments,
        limit: paginatedPayment.limit,
        offset: paginatedPayment.offset,
        total: paginatedPayment.total,
      }
      return paginatedPayments
    })
  }

  inProgressPayment(locationId: number, paymentId: string) {
    return axios.post(Endpoints.IN_PROGRESS_PAYMENT(this.company!.id, locationId, paymentId))
  }

  completePayment(locationId: number, paymentId: string) {
    return axios.post(Endpoints.COMPLETE_PAYMENT(this.company!.id, locationId, paymentId))
  }

  cancelPayment(locationId: number, paymentId: string, userId: string, notes: string | undefined) {
    return API.cancelPayment(this.company!.id, locationId, paymentId, userId, notes)
  }

  getSignedImageUploadUrl(fileExtension: string, contentType: string) {
    return axios
      .get(Endpoints.GET_SIGNED_IMAGE_UPLOAD_URL(this.company!.id, fileExtension, contentType))
      .then((response) => {
        const responseObject = response.data as {
          fileName: string
          signedUrl: string
        }
        return responseObject
      })
  }

  getSignedTempFileUploadUrl = async (fileExtension: string, contentType: string) => {
    try {
      const endPoint = Endpoints.GET_SIGNED_TEMP_FILE_UPLOAD_URL(
        this.company!.id,
        fileExtension,
        contentType,
      )
      const response = await axios.get(endPoint)
      return response.data as {
        fileName: string
        signedUrl: string
      }
    } catch (error) {
      console.error('Error in getSignedTempFileUploadUrl', error)
      throw error
    }
  }

  async getSignedImageUploadPublicUrl(): Promise<ISignedImageUploadContract> {
    try {
      const response = await axios.get(
        Endpoints.GET_SIGNED_IMAGE_UPLOAD_PUBLIC_URL(this.company!.id),
      )
      return response.data
    } catch (error) {
      console.error('Error in getSignedImageUploadPublicUrl', error)
      throw error
    }
  }

  getSignedAdminImageUploadPublicUrl = async (): Promise<ISignedImageUploadContract> => {
    try {
      const response = await axios.get(Endpoints.GET_SIGNED_ADMIN_IMAGE_UPLOAD_PUBLIC_URL())
      return response.data
    } catch (error) {
      console.error('Error in getSignedAdminImageUploadPublicUrl', error)
      throw error
    }
  }

  getSignedImageUrl(fileName: string) {
    return axios
      .get(Endpoints.GET_SIGNED_IMAGE_URL(this.company!.id, fileName))
      .then((response) => {
        const responseObject = response.data as {
          signedUrl: string
        }
        return responseObject
      })
  }

  getGraphValues(
    startDate: Date,
    endDate: Date,
    groupBy: 'hour' | 'day' | 'month',
    locationIds?: number[],
  ) {
    return API.getGraphValues(this.company!.id, startDate, endDate, groupBy, locationIds).then(
      (response) => {
        return response
      },
    )
  }

  updateBulkInventoryTransfer(
    fromLocationId: number,
    toLocationId: number,
    transferObjects: Array<{ productId: string; quantity: number; note?: string }>,
  ) {
    return axios.put(
      Endpoints.UPDATE_BULK_INVENTORY_TRANSFER(this.company!.id, fromLocationId, toLocationId),
      {
        inventoryToTransfer: transferObjects,
      },
    )
  }

  @action
  updateBulkInventory(locationId: number, bulkInventory: IUpdateBulkInventory) {
    return API.updateBulkInventory(this.company!.id, locationId, bulkInventory)
  }

  // WooCommerce

  @action
  syncWooCommerceProduct(productId: string) {
    return API.syncWooCommerceProduct(this.company!.id, productId)
  }

  @action
  syncWooCommerceInventory(productId: string) {
    return axios.post(Endpoints.SYNC_WOOCOMMERCE_INVENTORY(this.company!.id, productId))
  }

  @action
  deleteWooCommerceProduct(productId: string) {
    return axios.delete(Endpoints.DELETE_WOOCOMMERCE_PRODUCT(this.company!.id, productId))
  }

  // Loyalty

  @action
  getLoyaltyTiers() {
    return API.getLoyaltyTiers(this.company!.id).then((loyaltyTiers) => {
      this.loyaltyTiers = loyaltyTiers
      this.setSortedLoyaltyTiers(loyaltyTiers)
      return loyaltyTiers
    })
  }

  @action
  setSortedLoyaltyTiers(loyaltyTiers: IBaseLoyaltyTier[]) {
    this.sortedLoyaltyTiers = [
      ...loyaltyTiers.filter((loyalty) => loyalty.isDefault),
      ...loyaltyTiers.filter((loyalty) => !loyalty.isDefault),
    ]
  }

  @action
  addLoyaltyTier(createObject: IAddOrEditLoyaltyTier, updateAllCustomers = false) {
    return API.addLoyaltyTier(this.company!.id, createObject, updateAllCustomers).then(
      (loyaltyTier) => {
        this.getLoyaltyTiers()
        return loyaltyTier
      },
    )
  }

  @action
  editLoyaltyTier(
    loyaltyTierId: number,
    createObject: IAddOrEditLoyaltyTier,
    updateAllCustomers = false,
  ) {
    return API.editLoyaltyTier(
      this.company!.id,
      loyaltyTierId,
      createObject,
      updateAllCustomers,
    ).then((loyaltyTier) => {
      this.getLoyaltyTiers()
      return loyaltyTier
    })
  }

  @action
  getWeedmapsIntegrationStatus = async () => {
    const companyExternalSources = await API.getCompanyExternalSources(this.company!.id)
    const weedmapsExternalSource = companyExternalSources.find(
      (externalSource) => externalSource.name === 'Weedmaps',
    )
    this.isWeedmapsEnabled = !!(weedmapsExternalSource && weedmapsExternalSource.isEnabled)
  }

  @computed
  get isNegativeInventorySaleBlocked(): boolean {
    return this.company?.blockNegativeInventorySale || false
  }

  // Transfers
  getTransfers(filters: ITransferSearchFilter) {
    return TransfersApi.getTransfers(this.company!.id, filters)
  }

  getTransferById(transferId: string) {
    return API.getTransferById(this.company!.id, transferId)
  }

  createTransfer(createObject: CreateOrUpdateTransfer) {
    return API.createTransfer(this.company!.id, createObject)
  }

  updateTransfer(transferId: string, updateObject: CreateOrUpdateTransfer) {
    return API.updateTransfer(this.company!.id, transferId, updateObject)
  }

  updateCashCount(
    deviceId: string,
    shiftId: number,
    cashCountUpdateObject: ShiftModels.ICashCountUpdate,
  ) {
    return ShiftApi.updateCashCount(this.company!.id, deviceId, shiftId, cashCountUpdateObject)
  }

  isFeatureEnabled = async (
    featureId: string,
    userId?: string,
    locationId?: number,
  ): Promise<boolean> => {
    try {
      const { data } = await axios.get<FeatureToggleContract>(
        Endpoints.FEATURE_FLAG_STATUS(this.company!.id, featureId, userId, locationId),
      )
      return data.enabled
    } catch (error) {
      // Disable feature in case of error
      return false
    }
  }

  getGlobalProducts = async ({
    query,
    limit,
    offset,
  }: {
    query: string
    limit: number
    offset: number
  }): Promise<IGlobalProductResponse> => {
    const response = await axios.get<IGlobalProductResponse>(
      Endpoints.GET_GLOBAL_CATALOG_PRODUCTS(),
      {
        params: {
          limit,
          offset,
          query,
        },
      },
    )
    return response.data
  }

  @action
  getPOSListing = async (locationId?: number) => {
    if (!this.company || !this.locations || this.locations.length === 0) return

    const location = locationId || this.locations[0].id
    const posListing = await API.getPOSListings(this.company.id, location)

    this.posListing = posListing
    this.posListingFetchTime = new Date()
  }

  @action
  getWorldpayStatus = async () => {
    const canUseWorldpay = await this.isFeatureEnabled(FeatureToggle.CAN_USE_WORLDPAY)
    this.canUseWorldpay = canUseWorldpay
  }

  @action
  getCanUseGlobalCatalogStatus = async () => {
    const canUseGlobalCatalog = await this.isFeatureEnabled(FeatureToggle.CAN_USE_GLOBAL_CATALOG)
    this.canUseGlobalCatalog = canUseGlobalCatalog
  }

  @action
  getCannabinoidPerBatchStatus = async () => {
    const canUseCannabinoidPerBatch = await this.isFeatureEnabled(
      FeatureToggle.CANNABINOID_PER_BATCH,
    )

    this.canUseCannabinoidPerBatch = canUseCannabinoidPerBatch
  }

  @action
  getViewEcommerceSettings = async () => {
    const canViewEcommerceSettings = await this.isFeatureEnabled(
      FeatureToggle.CAN_VIEW_ECOMMERCE_SETTINGS,
    )
    this.canViewEcommerceSettings = canViewEcommerceSettings
  }

  @action
  getCanViewDigitalSignage = async () => {
    const canViewDigitalSignage = await this.isFeatureEnabled(
      FeatureToggle.CAN_VIEW_DIGITAL_SIGNAGE,
    )
    this.canViewDigitalSignage = canViewDigitalSignage
  }

  @action
  getCanUseInsightsQuicksight = async (userId?: string) => {
    const canUseInsights = await this.isFeatureEnabled(
      FeatureToggle.CAN_USE_INSIGHTS_QUICKSIGHT,
      userId,
    )

    this.canUseInsightsQuicksight = canUseInsights
  }

  @action
  getCanUseBroadcasts = async () => {
    const canUseBroadcastsFlag = await this.isFeatureEnabled(
      FeatureToggle.CAN_USE_BROADCASTS_SERVICE,
    )

    this.canUseBroadcasts = canUseBroadcastsFlag
  }

  @action
  setDisplayInsightsReportNavBar = async (display: boolean) => {
    this.isInsightsNavbarDisplayed = display
  }

  @action
  getProductDetailsPackageDateStatus = async () => {
    const productDetailsPackageDate = await this.isFeatureEnabled(
      FeatureToggle.PRODUCT_DETAILS_PACKAGE_DATE,
    )
    this.productDetailsPackageDate = productDetailsPackageDate
  }

  @action
  getProductDetailsTerpenesAndCannbinoidsStatus = async () => {
    const productDetailsTerpenesAndCannabinoids = await this.isFeatureEnabled(
      FeatureToggle.PRODUCT_DETAILS_TERPENES_AND_CANNABINOIDS,
    )
    this.productDetailsTerpenesAndCannabinoids = productDetailsTerpenesAndCannabinoids
  }

  @action
  getIRCCStatus = async () => {
    const ircc = await this.isFeatureEnabled(FeatureToggle.IRCC)
    this.ircc = ircc
  }

  @action
  setShowBundledDiscounts = (showBundledDiscounts: boolean, permissions: DiscountsPermissions) => {
    this.productStore?.getDiscounts({ permissions })
    this.showBundledDiscounts = showBundledDiscounts
  }

  @action
  getCanUseNewMBComplianceReport = async () => {
    const canUseNewMBComplianceReport = await this.isFeatureEnabled(
      FeatureToggle.CAN_USE_NEW_MB_COMPLIANCE_REPORT,
    )
    this.canUseNewMBComplianceReport = canUseNewMBComplianceReport
  }

  @action
  getCanUseOCSComplianceStatus = async () => {
    const canUseOCSCompliance = await this.isFeatureEnabled(FeatureToggle.CAN_USE_OCS_COMPLIANCE)
    this.canUseOCSCompliance = canUseOCSCompliance
  }

  @action
  async refreshLoyaltyPointsMultiplier() {
    const companyWithMetaData: ICompanyWithMetaDataContract<MetaDataType.INTEGER> =
      await API.getCompanyById(this.company!.id, true)

    const loyaltyPointsMultiplier = companyWithMetaData.metaData[MetaKeys.loyaltyPointsMultiplier]
    if (isNil(loyaltyPointsMultiplier)) {
      this.loyaltyPointsMultiplier = 100
      return
    }

    this.loyaltyPointsMultiplier = loyaltyPointsMultiplier
  }

  @action
  getCanUseCustomReceipt = async () => {
    const canUseCustomReceipt = await this.isFeatureEnabled(FeatureToggle.CAN_USE_CUSTOM_RECEIPT)
    this.canUseCustomReceipt = canUseCustomReceipt
  }

  @action
  getCanDisplayLoyaltyInfoOnReceipt = async () => {
    const canDisplayLoyaltyInfoOnReceipt = await this.isFeatureEnabled(
      FeatureToggle.CAN_DISPLAY_LOYALTY_INFO_ON_RECEIPT,
    )

    this.canDisplayLoyaltyInfoOnReceipt = canDisplayLoyaltyInfoOnReceipt
  }

  @action
  getCanUseBulkProductUpdate = async () => {
    const canUseBulkProductUpdate = await this.isFeatureEnabled(
      FeatureToggle.CAN_USE_BULK_PRODUCT_UPDATE,
    )
    this.canUseBulkProductUpdate = canUseBulkProductUpdate
  }

  @action
  getCanUseGlobalLogin = async () => {
    const canUseGlobalLogin = await this.isFeatureEnabled(FeatureToggle.CAN_USE_GLOBAL_LOGIN)
    this.canUseGlobalLogin = canUseGlobalLogin
  }

  setCustomReceiptSetting = async (locationId: number) => {
    const savedReceiptSetting = this.customReceiptSettings.get(locationId)

    if (!this.company || savedReceiptSetting) {
      return
    }

    const receiptSetting = await ReceiptApi.getReceipt(this.company.id, locationId)

    receiptSetting && this.customReceiptSettings.set(locationId, receiptSetting)
  }

  @action
  getCanUseLoyaltyService = async () => {
    const canUseLoyaltyService = await this.isFeatureEnabled(FeatureToggle.CAN_USE_LOYALTY_SERVICE)
    this.canUseLoyaltyService = canUseLoyaltyService
  }

  @action
  getExternalLoyaltyProvider = async (companyId: number) => {
    const externalLoyaltyConfigs = await ExternalLoyaltyAPI.getExternalLoyaltyConfigs({
      gatewayUrl: Endpoints.PLATFORM_GATEWAY_URL,
      merchantId: `blc-${companyId}`,
    })
    const enabledExternalProvider = externalLoyaltyConfigs.find(
      (externalLoyaltyConfig: any) =>
        externalLoyaltyConfig.loyaltyProviderEnabled &&
        externalLoyaltyConfig.loyaltyProvider !==
          ExternalLoyaltyModels.ExternalLoyaltyProviders.GREENLINE,
    )

    this.canUseExternalLoyalty = enabledExternalProvider?.loyaltyProviderEnabled || false
    this.externalLoyaltyProvider = enabledExternalProvider?.loyaltyProvider || null
    this.externalLoyaltyProviderConfig = enabledExternalProvider || null
  }

  @action
  getExternalLoyaltyDiscounts = async (
    payment?: CreatePayment,
  ): Promise<Map<string, ExternalLoyaltyModels.IRewardContract>> => {
    if (!this.canUseExternalLoyalty) {
      return new Map<string, ExternalLoyaltyModels.IRewardContract>()
    }
    const customerId = payment?.customer?.id
    let externalLoyaltyDiscounts: Map<string, ExternalLoyaltyModels.IRewardContract> = new Map()
    let externalCustomerDiscounts: ExternalLoyaltyModels.IRewardContract[] = []
    let externalDiscounts: ExternalLoyaltyModels.IRewardContract[] = []
    if (customerId) {
      try {
        externalDiscounts = await ExternalLoyaltyAPI.getExternalLoyaltyDiscounts({
          gatewayUrl: Endpoints.PLATFORM_GATEWAY_URL,
          merchantId: this.merchantId,
        })
        // eslint-disable-next-line no-empty
      } catch (error) {}

      try {
        const { id, email, phone } = payment?.customer
        externalCustomerDiscounts = await ExternalLoyaltyAPI.getExternalLoyaltyCustomerDiscounts({
          gatewayUrl: Endpoints.PLATFORM_GATEWAY_URL,
          merchantId: this.merchantId,
          customer: { id, email, phone },
        })
        // eslint-disable-next-line no-empty
      } catch (error) {}

      const externalCustomerDiscountMap = new Map<string, ExternalLoyaltyModels.IRewardContract>()
      externalCustomerDiscounts.forEach((externalCustomerDiscount) => {
        externalCustomerDiscountMap.set(
          externalCustomerDiscount.discountId,
          externalCustomerDiscount,
        )
      })

      externalDiscounts.forEach((externalDiscount) => {
        if (!externalCustomerDiscountMap.get(externalDiscount.discountId)) {
          externalCustomerDiscountMap.set(externalDiscount.discountId, {
            ...externalDiscount,
            redeemable: false,
          })
        }
      })
      externalLoyaltyDiscounts = externalCustomerDiscountMap
    } else {
      try {
        externalDiscounts = await ExternalLoyaltyAPI.getExternalLoyaltyDiscounts({
          gatewayUrl: Endpoints.PLATFORM_GATEWAY_URL,
          merchantId: this.merchantId,
        })
        // eslint-disable-next-line no-empty
      } catch (error) {}
      const externalDiscountMap = new Map<string, ExternalLoyaltyModels.IRewardContract>()
      externalDiscounts.forEach((externalDiscount) => {
        externalDiscountMap.set(externalDiscount.discountId, externalDiscount)
      })
      externalLoyaltyDiscounts = externalDiscountMap
    }
    runInAction(() => {
      const observableExternalDiscounts = observable.map(externalLoyaltyDiscounts)
      this.productStore?.setExternalDiscounts(observableExternalDiscounts)
    })
    return externalLoyaltyDiscounts
  }

  getFilteredExternalDiscounts = (
    availableDiscounts: DiscountModels.IDiscount[],
    payment?: CreatePayment,
  ): DiscountModels.IDiscount[] => {
    const appliedDiscountsMap = new Map<string, { usageCount: number }>()

    payment?.paymentLines.forEach((paymentLine) => {
      const discountId = paymentLine.discountId?.toString()
      if (!discountId) return

      const existingDiscount = appliedDiscountsMap.get(discountId)
      const usageCount = existingDiscount ? existingDiscount.usageCount + 1 : 1

      appliedDiscountsMap.set(discountId, { usageCount })
    })

    const canUseExternalLoyalty = this.canUseExternalLoyalty
    const customerId = payment?.customer?.id
    const externalDiscounts = this.productStore?.externalDiscounts
    if (!canUseExternalLoyalty) return availableDiscounts

    const availableExternalDiscounts: DiscountModels.IDiscount[] = []

    availableDiscounts.forEach((bundleDiscount) => {
      const externalDiscount = externalDiscounts?.get(bundleDiscount.id.toString())
      if (!externalDiscount) {
        availableExternalDiscounts.push(bundleDiscount)
      }

      const discountAlreadyApplied = appliedDiscountsMap.get(bundleDiscount.id.toString())
      const redemptionsAreLimited = Number(externalDiscount?.redemptionsAvailable ?? 0) > 0
      const hasExpiredRedemptions =
        discountAlreadyApplied &&
        externalDiscount &&
        redemptionsAreLimited &&
        externalDiscount.redemptionsAvailable <= discountAlreadyApplied.usageCount

      if (hasExpiredRedemptions) return

      if (externalDiscount?.redeemable && customerId) {
        availableExternalDiscounts.push({ ...bundleDiscount, isLoyalty: true })
      }

      if (discountAlreadyApplied && externalDiscount && redemptionsAreLimited) {
        this.productStore?.updateExternalDiscountByDiscountId(externalDiscount.discountId, {
          ...externalDiscount,
          redemptionsAvailable:
            externalDiscount.redemptionsAvailable - discountAlreadyApplied.usageCount,
        })
      }
    })

    return availableExternalDiscounts
  }

  findExternalDiscount = (
    discountId?: number,
  ): ExternalLoyaltyModels.IRewardContract | undefined => {
    if (!discountId) {
      return
    }
    return this.productStore?.externalDiscounts.get(discountId.toString())
  }

  private get merchantId(): string {
    return this.company ? `blc-${this.company.id}` : ''
  }

  @action
  getMerchantId = () => {
    return this.merchantId
  }

  @action
  getShouldDisplayInternalLoyaltyData = async (locationId: number) => {
    const { showLoyalty } = this.customReceiptSettings.get(locationId) || {}
    const { canAccessLoyalty } = this.company?.permissions || {}
    runInAction(() => {
      this.shouldDisplayInternalLoyaltyData = !!showLoyalty && !!canAccessLoyalty
    })
  }

  @action
  getCanUseCustomerSearchV2 = async () => {
    const canUseCustomerSearchV2 = await this.isFeatureEnabled(
      FeatureToggle.CAN_USE_CUSTOMER_SEARCH_V2,
    )
    this.canUseCustomerSearchV2 = canUseCustomerSearchV2
  }

  @action
  getCanDisplayOnlineOrdersReceipts = async () => {
    const canDisplayOnlineOrdersReceipts = await this.isFeatureEnabled(
      FeatureToggle.CAN_DISPLAY_ONLINE_ORDERS_RECEIPTS,
    )
    this.canDisplayOnlineOrdersReceipts = canDisplayOnlineOrdersReceipts
  }

  @action
  getCanUsePlatformCustomersSearch = async () => {
    const canUsePlatformCustomers = await this.isFeatureEnabled(
      FeatureToggle.CAN_USE_PLATFORM_CUSTOMERS_SEARCH,
    )
    this.canUsePlatformCustomers = canUsePlatformCustomers
  }

  @action
  getCanUsePlatformCustomersSearchOutreach = async () => {
    const canUsePlatformCustomersOutreach = await this.isFeatureEnabled(
      FeatureToggle.CAN_USE_PLATFORM_CUSTOMERS_SEARCH_OUTREACH,
    )
    this.canUsePlatformCustomersOutreach = canUsePlatformCustomersOutreach
  }

  @action
  getCanUseAutoLoyaltyDiscount = async () => {
    const canUseAutoLoyaltyDiscount = await this.isFeatureEnabled(
      FeatureToggle.CAN_USE_AUTO_LOYALTY_DISCOUNT,
    )
    this.canUseAutoLoyaltyDiscount = canUseAutoLoyaltyDiscount
  }

  @action
  getCanViewInventorySnapshots = async () => {
    const canViewInventorySnapshots = await this.isFeatureEnabled(
      FeatureToggle.CAN_VIEW_INVENTORY_SNAPSHOTS,
    )
    this.canViewInventorySnapshots = canViewInventorySnapshots
  }

  @action
  getLoyaltyData = async (payment: IPayment): Promise<ILoyaltyData | undefined> => {
    if (!this.company?.permissions.canAccessLoyalty) return

    const { canDisplayLoyaltyInfoOnReceipt, canUseCustomReceipt, customReceiptSettings } = this

    this.getShouldDisplayInternalLoyaltyData(payment.locationId)

    const loyaltyData = this.shouldDisplayInternalLoyaltyData
      ? await LoyaltyDiscountUtils.getLoyaltyData(
          this.company?.permissions,
          payment,
          {
            canDisplayLoyaltyInfoOnReceipt,
            canUseCustomReceipt,
          },
          customReceiptSettings.get(payment.locationId)?.showLoyalty,
        )
      : undefined

    return loyaltyData
  }

  getReceiptSettings = (company: ICompany, locationId: number): ReceiptModels.IReceiptContract => {
    const receiptSettings =
      this.customReceiptSettings.get(locationId) ||
      ReceiptUtils.generateDefaultReceiptSettings(company, locationId)

    return receiptSettings
  }

  @action
  getCrsaHashKeyMap = async (id?: number): Promise<Map<string, string> | undefined> => {
    const companyId = id || this.company?.id
    if (!companyId) throw new Error('Company ID is required')

    try {
      const { items } = await OCS.getHashKeys({
        gateway: Endpoints.AUTHZ_GATEWAY_URL,
        tenantId: `${companyId}`,
      })

      const crsaHashKeyMap = new Map<string, string>(
        items.map((item) => [item.retailerCRSA, item.hashKey]),
      )

      return crsaHashKeyMap
    } catch (error) {
      console.error('Error fetching hashkeys', error)
    }
  }

  @action
  getCanUseDiscountCode = async () => {
    const canUseDiscountCode = await this.isFeatureEnabled(FeatureToggle.CAN_USE_DISCOUNT_CODE)
    this.canUseDiscountCode = canUseDiscountCode
  }

  @action
  getCanUseSalesChannel = async () => {
    const canUseSalesChannel = await this.isFeatureEnabled(FeatureToggle.CAN_USE_SALES_CHANNEL)
    this.canUseSalesChannel = canUseSalesChannel
  }

  fetchSalesChannels = async () => {
    const companyId = this.company?.id
    if (!companyId) throw new Error('Company ID is required')

    return discountApi.getSalesChannels(companyId)
  }

  @action
  getSalesChannels = async () => {
    if (this.salesChannels.length === 0) {
      const salesChannels = await this.fetchSalesChannels()

      // Hide KIOSK and MOBILE sales channels from the UI - Remove this code when we want to display them
      const filteredSalesChannels = salesChannels.filter(
        (channel) => channel.id !== 3 && channel.id !== 4,
      )

      this.salesChannels = filteredSalesChannels
    }

    return this.salesChannels
  }

  private get mappedSalesChannels() {
    const mappedSalesChannels = new Map<
      number,
      DiscountModels.ISalesChannel & { displayName: string }
    >()

    Object.values(DiscountModels.SalesChannels).forEach((salesChannel) =>
      mappedSalesChannels.set(salesChannel.id, salesChannel),
    )

    return mappedSalesChannels
  }

  getSaleChannelDisplayName = (saleChannel: DiscountModels.ISalesChannel) => {
    return this.mappedSalesChannels.get(saleChannel.id)?.displayName || startCase(saleChannel.name)
  }
}
