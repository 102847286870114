export enum FeatureToggle {
  CREATE_PO_PRODUCTS = 'create-po-products',
  NEW_PRODUCT_SEARCH = 'new-product-search',
  CAN_USE_WORLDPAY = 'can-use-worldpay',
  CANNABINOID_PER_BATCH = 'cannabinoid-per-batch',
  CAN_VIEW_ECOMMERCE_SETTINGS = 'can-view-ecommerce-settings',
  CAN_VIEW_DIGITAL_SIGNAGE = 'can-view-digital-signage',
  CAN_USE_WEBSOCKET = 'websocket',
  PRODUCT_DETAILS_PACKAGE_DATE = 'product-details-package-date',
  PRODUCT_DETAILS_TERPENES_AND_CANNABINOIDS = 'product-details-terpenes-cannabinoids',
  ONLINE_ORDERING_MODULE = 'online-ordering-module',
  IRCC = 'IRCC',
  CAN_USE_NEW_MB_COMPLIANCE_REPORT = 'can-use-new-mb-compliance-report',
  CAN_USE_OCS_COMPLIANCE = 'ocs-compliance',
  CAN_USE_CUSTOM_RECEIPT = 'can-use-custom-receipt',
  CAN_DISPLAY_LOYALTY_INFO_ON_RECEIPT = 'can-display-loyalty-info-on-receipt',
  CAN_USE_BULK_PRODUCT_UPDATE = 'can-use-bulk-product-update',
  CAN_USE_INSIGHTS_QUICKSIGHT = 'can-use-insights-quicksight',
  CAN_USE_BROADCASTS_SERVICE = 'can-use-broadcasts-service',
  CAN_USE_LOYALTY_SERVICE = 'can-use-loyalty-service',
  CAN_USE_CUSTOMER_SEARCH_V2 = 'can-use-customer-search-v2',
  CAN_DISPLAY_ONLINE_ORDERS_RECEIPTS = 'can-display-online-orders-receipts',
  CAN_USE_PLATFORM_CUSTOMERS_SEARCH = 'can-use-platform-customers-search',
  CAN_USE_PLATFORM_CUSTOMERS_SEARCH_OUTREACH = 'can-use-platform-customers-search-outreach',
  CAN_USE_DISCOUNT_CODE = 'can-use-discount-code',
  CAN_USE_SALES_CHANNEL = 'can-use-sales-channel',
  CAN_USE_AUTO_LOYALTY_DISCOUNT = 'can-use-auto-loyalty-discount',
  CAN_VIEW_INVENTORY_SNAPSHOTS = 'can-view-inventory-snapshots',
  CAN_USE_KML_FILES = 'can-use-kml-files',
  CAN_USE_GLOBAL_CATALOG = 'can-use-global-catalog',
  CAN_USE_GLOBAL_LOGIN = 'can-use-global-login',
}
