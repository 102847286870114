import { AmplifyClassV6 } from '@aws-amplify/core'
import { amplifyConfig } from './aws-exports'
import { GraphQLAPI, GraphQLResult } from '@aws-amplify/api-graphql'
import { Observable, Subscription } from 'rxjs'
import { ISubscriptionParams } from './graphql/interfaces/subscription'
import { Hub } from 'aws-amplify/utils'
import { ConnectionState, CONNECTION_STATE_CHANGE } from '@aws-amplify/api'
import { action, observable } from 'mobx'
import { IAmplifyConnectionState } from './amplify/interfaces'
import { LocalStorage } from '../utilities/LocalStorage'
import { IQueryParams } from './graphql/interfaces/query'

export class AppSyncAmplify {
  readonly amplify: AmplifyClassV6
  authToken: string | undefined
  deviceAuthToken: string | undefined

  @observable
  connectionState: IAmplifyConnectionState = {
    previous: ConnectionState.Disconnected,
    current: ConnectionState.Disconnected,
  }

  constructor() {
    /**
     * Amplify.configure(amplifyConfig) does not work for some reason.
     * error: "Subscribe only available for AWS AppSync endpoint."
     * For some reason, internal "resolveConfig" function does not see "config.API.GraphQL" attribute even though
     * the value exists.
     *
     * please instantiate AmplifyClassV6 directly instead
     */
    this.amplify = new AmplifyClassV6()
    this.amplify.configure(amplifyConfig)
    this.addApiListener()
    this.initAuthToken()
  }

  createSubscription<T>(params: ISubscriptionParams<T>): Subscription {
    const observable = GraphQLAPI.graphql(this.amplify, {
      query: params.query,
      variables: params.variables,
      authMode: 'lambda',
      authToken: this.authToken ?? this.deviceAuthToken,
    }) as Observable<GraphQLResult<T>>

    const subscription = observable.subscribe(params.callbacks)
    subscription.add({ unsubscribe: params.teardown })

    return subscription
  }

  async performQuery(params: IQueryParams): Promise<unknown> {
    const authToken = this.deviceAuthToken ?? this.authToken
    const result = await GraphQLAPI.graphql(this.amplify, {
      query: params.query,
      authToken,
      variables: params.variables,
    })
    return result
  }

  // https://docs.amplify.aws/javascript/build-a-backend/graphqlapi/subscribe-data/
  private addApiListener() {
    Hub.listen('api', (data: any) => {
      const { payload } = data
      if (payload.event === CONNECTION_STATE_CHANGE) {
        this.setConnectionState({
          previous: this.connectionState.current,
          current: payload.data.connectionState,
        })
      }
    })
  }

  @action
  setConnectionState(connectionState: IAmplifyConnectionState) {
    this.connectionState = connectionState
  }

  private async initAuthToken() {
    this.authToken = (await LocalStorage.getAuthToken()) || undefined
    this.deviceAuthToken = (await LocalStorage.getDeviceAuthToken()) || undefined
  }
}
