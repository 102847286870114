export const mfeEndpointNames = {
  PRODUCT_PAGE: '@blaze/mfe-product-page',
  PAYMENT_PAGE: '@blaze/mfe-payment-page',
  LOYALTY_PAGE: '@blaze/mfe-loyalty-page',
  INSIGHTS_PAGE: '@blaze/mfe-insights-page',
  PAYMENT_ADMIN_PAGE: '@blaze/mfe-payment-page',
  COMPLIANCE_PAGE: '@blaze/mfe-compliance-page',
  CUSTOMERS_PAGE: '@blaze/mfe-customers-page',
}

const importMfe = (endPointName: string) => {
  const overrideEndPointName = localStorage.getItem(endPointName)

  /**
   * window.System is used to import systemjs importmap json
   * `System` is inaccessible in React components so it is attached to `window` in `public/index.html`.
   */
  return window.System.import(overrideEndPointName || endPointName)
}

export const mfeConfigs = {
  LOAD_PRODUCT_PAGE: () => importMfe(mfeEndpointNames.PRODUCT_PAGE),
  LOAD_PAYMENT_PAGE: () => importMfe(mfeEndpointNames.PAYMENT_PAGE),
  LOAD_LOYALTY_PAGE: () => importMfe(mfeEndpointNames.LOYALTY_PAGE),
  LOAD_INSIGHTS_PAGE: () => importMfe(mfeEndpointNames.INSIGHTS_PAGE),
  LOAD_PAYMENT_ADMIN_PAGE: () => importMfe(mfeEndpointNames.PAYMENT_ADMIN_PAGE),
  LOAD_COMPLIANCE_PAGE: () => importMfe(mfeEndpointNames.COMPLIANCE_PAGE),
  LOAD_CUSTOMERS_PAGE: () => importMfe(mfeEndpointNames.CUSTOMERS_PAGE),
}
