import React, { FC } from 'react'
import { inject } from 'mobx-react'
import { observer } from 'mobx-react-lite'
import { CurrentCompanyStore } from '../../stores/CurrentCompanyStore'
import { useEvents } from '../../utilities/hooks/useEvents'

interface ISharedComponentsPageProps {
  currentCompanyStore: CurrentCompanyStore
}

const _SharedComponentsPage: FC<ISharedComponentsPageProps> = ({ currentCompanyStore }) => {
  useEvents({ currentCompanyStore })

  return (
    <iframe
      src='https://blaze-staging-mfe.s3.amazonaws.com/storybook/index.html'
      style={{ width: '100%', height: '100vh', border: 'none' }}
      title='Storybook'
    />
  )
}

export const SharedComponentsPage = inject('currentCompanyStore')(observer(_SharedComponentsPage))
