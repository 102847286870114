import * as React from 'react'
import * as Tooltip from 'react-tooltip'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'
import { axios, API, ILocation, IDevice } from '@getgreenline/homi-shared'
import { LocationApi } from '@getgreenline/locations'
import { LoadingAnimation } from '../../../components/LoadingAnimation'
import { message, Switch } from 'antd'
import { parseErrorMsg } from '../../../utilities/helpers'
import { isEmpty } from 'lodash'
import { BlazepayAPI, BlazepayEnums } from '@getgreenline/payments'
interface Props {
  companyId: number
}

interface ILocationContract extends ILocation {
  isHidden: boolean
}

interface State {
  locations?: ILocationContract[]
  devices?: IDevice[]
  addDeviceLocationId?: number
  deviceToChangeName?: IDevice
  newDeviceName: string
  deviceId: string
  devicePassword: string
  offset: number
  loading: boolean
  hideDeleted: boolean
  allItemsInList: boolean
  adyenDeviceIds?: string[] | undefined
}

export class LocationsAndDevices extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      deviceId: '',
      devicePassword: '',
      newDeviceName: '',
      offset: 0,
      loading: false,
      hideDeleted: false,
      allItemsInList: false,
    }
  }

  componentDidMount = async () => {
    await this.refreshData()
    const tablerWrapper = document.getElementById('locations-and-devices-table')

    if (tablerWrapper) tablerWrapper.addEventListener('scroll', this.trackScrolling)
  }

  componentWillUnmount() {
    document.removeEventListener('scroll', this.trackScrolling)
  }

  trackScrolling = async () => {
    const scrollElement = document.getElementById('locations-and-devices-table')

    if (scrollElement && this.isBottom(scrollElement) && !this.state.loading) {
      this.setState({
        loading: true,
        offset: this.state.locations?.length || 0,
      })
      await this.refreshData()
    }
  }

  getAdyenDeviceIds = async (companyId: number) => {
    try {
      const terminals = await BlazepayAPI.getTerminals({
        merchantId: `blc-${companyId}`,
        filter: {
          provider: BlazepayEnums.Provider.ADYEN,
        },
      })
      const terminalIds = terminals.data
        .filter(
          (terminalDataObject) =>
            terminalDataObject.attributes.provider === BlazepayEnums.Provider.ADYEN,
        )
        .map((terminalDataObject) => terminalDataObject.attributes.terminalId)
      return terminalIds
    } catch (err) {
      message.error(err)
    }
  }

  async refreshData() {
    if (this.state.allItemsInList) {
      return
    }
    const { companyId } = this.props
    const locations = await axios.get(`/api/v1/admin/companies/${companyId}/locations`, {
      params: {
        limit: 10,
        offset: this.state.offset,
      },
    })

    if (isEmpty(locations.data.data)) {
      this.setState({
        allItemsInList: true,
      })
      return
    }

    const devices = await API.getDevices(companyId)
    const adyenDeviceIds = await this.getAdyenDeviceIds(companyId)

    const allLocationsAreDeleted = locations.data.data.filter(
      (location: ILocationContract) => !location.isHidden,
    )

    if (this.state.hideDeleted && isEmpty(allLocationsAreDeleted)) {
      this.setState({
        loading: true,
        offset: this.state.locations?.length + locations.data.data.length,
      })
      this.refreshData()
    }

    this.setState({
      locations: isEmpty(this.state.locations)
        ? locations.data.data
        : this.state.locations?.concat(locations.data.data),
      devices: devices,
      adyenDeviceIds: adyenDeviceIds,
    })

    if (this.state.loading) {
      this.setState({
        loading: false,
      })
    }
  }

  async refreshDataOnUndeleteOrAddDevice() {
    const { companyId } = this.props
    const locations = await axios.get(`/api/v1/admin/companies/${companyId}/locations`, {
      params: {
        limit: this.state.locations?.length,
        offset: 0,
      },
    })

    const devices = await API.getDevices(companyId)

    this.setState({
      locations: locations.data.data,
      devices: devices,
    })

    if (this.state.loading) {
      this.setState({
        loading: false,
      })
    }
  }

  addDevice() {
    axios
      .post(`/api/v1/admin/device`, {
        email: this.state.deviceId,
        name: 'device',
        password: this.state.devicePassword,
        entityIds: [this.state.addDeviceLocationId],
      })
      .then((response) => {
        this.setState({
          addDeviceLocationId: undefined,
        })
        message.success('New device added')
        this.refreshDataOnUndeleteOrAddDevice()
      })
      .catch((error) => {
        message.error(parseErrorMsg(error) || 'There was an error')
      })
  }

  isBottom(el: Element) {
    return Math.ceil(el.scrollHeight) - Math.ceil(el.scrollTop) <= el.clientHeight + 10
  }

  async changeDeviceName() {
    const { deviceToChangeName, newDeviceName } = this.state
    if (deviceToChangeName && newDeviceName) {
      try {
        await axios.put(`/api/v1/admin/device/${deviceToChangeName.id}`, {
          deviceId: newDeviceName,
        })
        this.setState({
          deviceToChangeName: undefined,
        })
        message.success('Device ID changed')
        this.refreshDataOnUndeleteOrAddDevice()
      } catch (error) {
        message.error(parseErrorMsg(error) || 'There was an error')
      }
    }
  }

  unDeleteLocation = async (locationId: number) => {
    const { companyId } = this.props

    try {
      await LocationApi.unDeleteLocation(companyId, { id: locationId, companyId, isHidden: false })
      message.success('Location successfully undeleted')
      this.refreshDataOnUndeleteOrAddDevice()
    } catch (err) {
      message.error(parseErrorMsg(err) || 'There was an error')
    }
  }

  deleteDevice = async () => {
    const { deviceToChangeName } = this.state

    const shouldDelete = confirm('Are you sure you want to delete this device ?')
    if (shouldDelete) {
      try {
        await axios.delete(`/api/v1/admin/devices/${deviceToChangeName?.id}`)
        await this.refreshDataOnUndeleteOrAddDevice()

        message.success('Device succesfully removed')
      } catch (err) {
        message.error(parseErrorMsg(err) || 'There was an error')
      }
    }
    this.setState({ deviceToChangeName: undefined })
  }

  height = () => {
    const { locations, hideDeleted, devices } = this.state

    const devicesHeight = devices ? devices.length * 20 : 0

    if (hideDeleted) {
      const undeletedLocations = locations?.filter((location) => !location.isHidden)

      if (undeletedLocations) return undeletedLocations?.length * 25 + devicesHeight
    }

    return locations ? locations?.length * 25 + devicesHeight : 150
  }

  render() {
    const { locations, devices, adyenDeviceIds } = this.state

    if (!locations || !devices) {
      return <LoadingAnimation />
    }

    return (
      <div className='background-shadow p-4'>
        <div style={{ display: 'flex' }}>
          <h5 className='text-muted'>
            <span className='mr-2'>Locations & devices</span>
          </h5>
          <div style={{ marginLeft: 'auto', fontWeight: 'bold' }}>
            <Switch
              style={{ marginRight: '8px' }}
              onChange={() => {
                this.setState({ hideDeleted: !this.state.hideDeleted })
              }}
            />
            Hide deleted locations
          </div>
          <br />
        </div>
        <div
          id={'locations-and-devices-table'}
          style={{
            height: this.height(),
            minHeight: '150px',
            maxHeight: '400px',
            overflowY: 'scroll',
          }}
        >
          <table className='table table-hover table-sm'>
            <tbody>
              {locations.map((location) => {
                if (location.isHidden && this.state.hideDeleted) return
                const matchingDevices = devices.filter(
                  (device) => device.locationId === location.id,
                )
                return (
                  <React.Fragment key={location.id}>
                    <tr>
                      <td>
                        <span className='text-muted mr-2'>{location.id}</span>
                        <b>{location.name}</b>
                        {location.isHidden && (
                          <span
                            className='badge badge-danger text-left ml-2'
                            style={{ color: 'white', textIndent: 0 }}
                          >
                            Deleted
                          </span>
                        )}
                      </td>
                      <td className='text-right'>
                        {location.isHidden ? (
                          <a onClick={() => this.unDeleteLocation(location.id)}>
                            <span className='fa fa-plus mr-2' />
                            Undelete
                          </a>
                        ) : (
                          <a onClick={() => this.setState({ addDeviceLocationId: location.id })}>
                            <span className='fa fa-plus mr-2' />
                            Add device
                          </a>
                        )}
                      </td>
                      <td className='text-right'>
                        <span
                          className='fa fa-bars text-muted'
                          style={{ cursor: 'pointer' }}
                          data-tip
                          data-for={'location-' + location.id}
                        />
                        <Tooltip id={'location-' + location.id} place='right' effect='solid'>
                          <pre className='text-left text-white'>
                            {JSON.stringify(location, null, 2)}
                          </pre>
                        </Tooltip>
                      </td>
                    </tr>
                    {matchingDevices.map((device) => {
                      const isMerrco =
                        device.merrcoApiAuthKey &&
                        device.merrcoCompanyNumber &&
                        device.merrcoMerchantNumber &&
                        device.merrcoMerchantTerminalNumber
                      const isMoneris = device.terminalIpAddress && device.terminalPort
                      const isBlazePay = adyenDeviceIds?.includes(device.id)
                      const merrcoBadge = isMerrco && (
                        <span className='badge badge-secondary mr-2'>Merrco</span>
                      )
                      const monerisBadge = isMoneris && (
                        <span className='badge badge-secondary mr-2'>Moneris</span>
                      )
                      const blazePayBadge = isBlazePay && (
                        <span className='badge badge-secondary mr-2'>BlazePay</span>
                      )
                      return (
                        <tr key={device.id}>
                          <td style={{ paddingLeft: 80 }}>
                            <a
                              className='mr-2'
                              onClick={() =>
                                this.setState({
                                  newDeviceName: device.deviceId,
                                  deviceToChangeName: device,
                                })
                              }
                            >
                              {device.deviceId}
                            </a>
                            {merrcoBadge}
                            {monerisBadge}
                            {blazePayBadge}
                          </td>
                          <td className='text-right text-muted'>{device.id}</td>
                          <td className='text-right'>
                            <span
                              className='fa fa-bars text-muted'
                              style={{ cursor: 'pointer' }}
                              data-tip
                              data-for={device.id}
                            />
                            <Tooltip id={device.id} place='right' effect='solid'>
                              <pre className='text-left text-white'>
                                {JSON.stringify(device, null, 2)}
                              </pre>
                            </Tooltip>
                          </td>
                        </tr>
                      )
                    })}
                  </React.Fragment>
                )
              })}
            </tbody>
          </table>

          <Modal
            isOpen={!!this.state.addDeviceLocationId}
            toggle={() => this.setState({ addDeviceLocationId: undefined })}
            autoFocus={false}
          >
            <ModalHeader>Add POS device</ModalHeader>
            <ModalBody>
              <div>
                <label>Location ID: {this.state.addDeviceLocationId}</label>
              </div>
              <div className='form-group'>
                <label>Device ID</label>
                <input
                  className='form-control'
                  value={this.state.deviceId}
                  onChange={(e) => this.setState({ deviceId: e.target.value })}
                />
              </div>
              <div className='form-group'>
                <label>Device Password</label>
                <input
                  className='form-control'
                  value={this.state.devicePassword}
                  onChange={(e) => this.setState({ devicePassword: e.target.value })}
                />
              </div>
              <button className='btn btn-success' onClick={() => this.addDevice()}>
                Add POS device
              </button>
            </ModalBody>
          </Modal>

          {this.state.deviceToChangeName && (
            <Modal isOpen={true} toggle={() => this.setState({ deviceToChangeName: undefined })}>
              <ModalHeader>Change POS device ID</ModalHeader>
              <ModalBody>
                <div className='form-group'>
                  <label>Device ID</label>
                  <div className='text-muted'>
                    Original device ID: {this.state.deviceToChangeName.deviceId}
                  </div>
                  <input
                    className='form-control'
                    value={this.state.newDeviceName}
                    onChange={(e) => this.setState({ newDeviceName: e.target.value })}
                  />
                </div>
                <div className='alert alert-warning'>
                  Make sure customers are told about all changes made to their devices!
                </div>
                <button className='btn btn-danger mr-2' onClick={() => this.deleteDevice()}>
                  Remove device
                </button>
                <button className='btn btn-success' onClick={() => this.changeDeviceName()}>
                  Change device ID
                </button>
              </ModalBody>
            </Modal>
          )}
        </div>
      </div>
    )
  }
}
