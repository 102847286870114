import { message } from 'antd'
import { observable, computed, action, reaction } from 'mobx'
import { uniq } from 'lodash'
import {
  API,
  ICompany,
  IDevice,
  ILocation,
  UserPermission,
  PermissionNames,
  ICreatePayment,
  CreatePayment,
  IUserPreference,
  IUserPreferenceUpdate,
  ITransaction,
  IPaymentQueueWithExternalSource,
  PaymentQueueStatus,
  IPayment,
  PaymentTypeIds,
  CreateTransaction,
  TransactionStatus,
} from '@getgreenline/homi-shared'
import { LocalStorage } from '../utilities/LocalStorage'
import { isWorldpayTransaction, parseErrorMsg } from '../utilities/helpers'
import { Track } from '../Track'
import { ProductStore } from './CurrentCompanyStore/ProductStore'
import {
  BlazepayPaymentModels,
  BlazepayRefundModels,
  isPaymentLineNegativeInventorySale,
  isPaymentQueueLineNegativeInventorySale,
  negativeInventorySaleMessage,
  WorldpayGlobalModels,
  WorldpayLaneModels,
  WorldpayTransactionModels,
} from '@getgreenline/payments'
import moment from 'moment'
import notificationSound from '../audio/parked_sale_notification.wav'
import { setRootAuthToken } from '../utilities/environment'
import { PosListingModels, PosListingAPI } from '@getgreenline/inventory'
import { ProductV2Api, taxApi } from '@getgreenline/products'
import { CurrentCompanyStore } from './CurrentCompanyStore'
import { WebSocketService, RouteKeys } from '@getgreenline/websocket'
import { WebSocketStore } from './WebsocketStore'
import { EventTopics, CrudEventTypes } from '@getgreenline/events/build/v2/models'
import { PaymentQueueEventDataContract } from '@getgreenline/payment-queues'
import { FeatureToggle } from '../constants/FeatureToggles'
import { CategoriesModels } from '@getgreenline/categories'
import { PaymentServiceStore } from './PaymentServiceStore/PaymentServiceStore'
import { ShiftApi, ShiftModels } from '@getgreenline/shifts'
import { isEcomPaymentServiceTransaction } from '../containers/POS/Sale/ConfirmModal/PaymentOptions/PaymentOptionHelper'
import { correctForEcomPaymentServicePayment } from '../containers/POS/ProcessPayment/ProcessPaymentHelper'

interface IBasicEmployee {
  employeeId: string
  employeeName: string
}

interface IEmployeeWithTrackingStatus extends IBasicEmployee {
  tracking: boolean
}

const LONG_EMPLOYEE_SHIFT_THRESHOLD = 24 * 60 * 60 * 1000 // 24 hrs In Milliseconds. Used for determine threshold for long shift warning

class POSListingSearchEngine {
  @observable
  showOnlyInStock = true

  @observable
  // Can be either a standalone or parent/variant category, undefined means no category selected, string means category selected, null means uncategorized
  categoryId?: string | null

  @observable
  filterQuery = ''

  // Used to indicate which parent product has been selected to view the variants of. Brings up the VariantSelectorModal on the UI
  @observable
  selectedTopLevelListing?: PosListingModels.IPOSListing

  // Used to indicate which product has been selected to view the details of.
  @observable
  selectedListingToDetailView?: PosListingModels.IPOSListing

  // Used to check if lots items should be added to cart from the product details modal
  @observable
  shouldProductDetailsAddToCart = true

  @observable
  POSListings = new PosListingModels.POSListings()

  @observable
  private POSProductsFetchTime: Date | null = null

  @observable
  private POSInventoryFetchTime: Date | null = null

  @observable
  POSListingsWithFilters = new PosListingModels.POSListingsWithFilters()

  @observable
  currentCompanyStore: CurrentCompanyStore

  @observable
  useSearchBar = false

  constructor(
    public company: ICompany,
    public device: IDevice,
    public productStore: ProductStore,
    currentCompanyStore: CurrentCompanyStore,
  ) {
    this.currentCompanyStore = currentCompanyStore

    reaction(
      () => {
        return {
          canUseCannabinoidPerBatch: Boolean(this.currentCompanyStore.canUseCannabinoidPerBatch),
          canUseProductDetailsTerpenesAndCannabinoids:
            this.currentCompanyStore.productDetailsTerpenesAndCannabinoids,
        }
      },
      ({ canUseCannabinoidPerBatch, canUseProductDetailsTerpenesAndCannabinoids }) => {
        const { setCanUseCannabinoidPerBatch, setCanUseProductDetailsTerpenesAndCannabinoids } =
          this.POSListingsWithFilters

        setCanUseCannabinoidPerBatch(canUseCannabinoidPerBatch)

        setCanUseProductDetailsTerpenesAndCannabinoids(canUseProductDetailsTerpenesAndCannabinoids)
      },
    )
  }

  @action
  setUseSearchBar = (useSearchBar: boolean) => {
    this.useSearchBar = useSearchBar
  }

  @action
  setShowOnlyInStock(show: boolean) {
    this.showOnlyInStock = show
  }

  @action
  setCategoryId(categoryId?: string | null) {
    this.categoryId = categoryId
  }

  @action
  setQuery(query?: string) {
    this.filterQuery = query || ''
  }

  @action
  setSelectedTopLevelListing = (selectedTopLevelListing?: PosListingModels.IPOSListing) => {
    this.selectedTopLevelListing = selectedTopLevelListing
  }

  @action
  setSelectedListingToView = (listing?: PosListingModels.IPOSListing, allowAddToCart?: boolean) => {
    this.selectedListingToDetailView = listing
    this.shouldProductDetailsAddToCart = allowAddToCart === undefined ? true : allowAddToCart
  }

  /**
   * In UI, we only show standalone and master products upon selecting a category
   * We actually don't need nested products
   */
  private getStockFilterTopLevelListings = (topLevelListings: PosListingModels.IPOSListing[]) => {
    if (!this.showOnlyInStock) return topLevelListings

    return topLevelListings.filter((p) => {
      if (p.trackInventory) return p.quantity > 0

      const childListings = this.POSListings.getChildren(p.id)

      // Include tandalone listings
      if (childListings.length === 0) return true

      return childListings.find((cl) => {
        return !cl.trackInventory || (cl.trackInventory && cl.quantity > 0)
      })
    })
  }

  @computed
  get filteredListings(): PosListingModels.IPOSListing[] {
    if (this.filterQuery.length > 2) {
      const filteredTopLevelListings = this.getStockFilterTopLevelListings(
        this.POSListings.allTopLevels,
      )
      const sanitizedQuery = this.filterQuery.trim().toLowerCase()

      // Combine all target fields into a single string
      const targetSearchString = (listing: PosListingModels.IPOSListing) => {
        const name = (listing.name || '').toLowerCase()
        const sku = (listing.sku || '').toLowerCase()
        const barcode = (listing.barcode || '').toLowerCase()

        return [name, sku, barcode].join(' ')
      }

      return filteredTopLevelListings.filter((topLevelListing) => {
        if (targetSearchString(topLevelListing).includes(sanitizedQuery)) return true

        return this.POSListings.getChildren(topLevelListing.id).some((childListing) => {
          return targetSearchString(childListing).includes(sanitizedQuery)
        })
      })
    }

    const { categoryId } = this

    if (categoryId) {
      if (categoryId === PosListingModels.FilterVocabulary.UNCATEGORIZED_NAME) {
        const categoryTopLevelListings = this.POSListings.getTopLevelsByCategoryId(null)

        return this.getStockFilterTopLevelListings(categoryTopLevelListings)
      }

      const categoryTopLevelListings = this.POSListings.getTopLevelsByCategoryId(categoryId)

      const targetCategory = (this.productStore.categories || []).find(
        (category) => category.id === categoryId,
      )

      const childCategoryTopLevelListings =
        targetCategory?.childCategories.reduce((acc: PosListingModels.IPOSListing[], curr) => {
          return [...acc, ...this.POSListings.getTopLevelsByCategoryId(curr.id)]
        }, []) || []

      const sortedListings = [...categoryTopLevelListings, ...childCategoryTopLevelListings].sort(
        (a, b) => a.name.localeCompare(b.name),
      )

      return this.getStockFilterTopLevelListings(sortedListings)
    }

    return this.getStockFilterTopLevelListings(this.POSListings.allTopLevels)
  }

  @action
  setPOSListing = async () => {
    await this.setPOSRefData()
    await this.setPOSProducts()
    await this.setPOSInventory()
  }

  @action
  setPOSRefData = async () => {
    const categories = await this.productStore.getCategories()
    const suppliers = await this.productStore.getSuppliers()
    const taxGroups = await this.productStore.getTaxGroups()
    const taxes = await taxApi.getTaxes()

    this.POSListings.refData.updateAll({ categories, suppliers, taxGroups, taxes })
  }

  @action
  setPOSProducts = async () => {
    const products = await ProductV2Api.getProducts(this.company.id, {
      locationId: this.device.locationId,
      updateDate: this.POSProductsFetchTime,
    })

    if (this.POSProductsFetchTime === null) {
      this.POSListings.products.setFreshAll(products)
    } else {
      this.POSListings.products.updateAll(products)
    }

    this.POSProductsFetchTime = new Date()
  }

  @action
  setPOSInventory = async () => {
    let canUseCannabinoidPerLot = this.currentCompanyStore.canUseCannabinoidPerBatch
    if (canUseCannabinoidPerLot === undefined) {
      canUseCannabinoidPerLot = await this.currentCompanyStore.isFeatureEnabled(
        FeatureToggle.CANNABINOID_PER_BATCH,
      )
    }

    const posListings = await PosListingAPI.getPOSListingsV2(
      this.company.id,
      this.device.locationId,
      {
        updateDate: this.POSInventoryFetchTime,
        canUseCannabinoidPerLot,
      },
    )

    this.POSListings.productInventory.updateAll(posListings.inventory)

    if (this.POSInventoryFetchTime === null) {
      this.POSListings.products.batches.setFreshAll(posListings.batchInventory)
    } else {
      this.POSListings.products.batches.updateAll(posListings.batchInventory)
    }

    this.POSInventoryFetchTime = new Date()
  }

  @computed
  get areListingsReady() {
    return this.POSProductsFetchTime !== null && this.POSInventoryFetchTime !== null
  }
}

export class POSStore extends POSListingSearchEngine {
  @action
  public static loginDevice(deviceId: string, password: string) {
    return API.loginDeviceV2(deviceId, password).then((response) => {
      Track.track('pos:device_login')
      const device = response.device
      const authToken = response.authToken
      window.localStorage.setItem('deviceAuthToken', authToken)
      window.location.reload()
      return device
    })
  }

  @observable
  company: ICompany

  @observable
  device: IDevice

  @observable
  location: ILocation

  @observable
  productStore: ProductStore

  @observable
  private onlineOrdersSet: Set<number>

  @observable
  private lastUpdatedPaymentQueues: moment.Moment

  @observable
  paidPayment: IPayment | undefined

  @observable
  worldpayTransactionResponse?: WorldpayTransactionModels.Internal.ITransactionResponseContract

  constructor(
    company: ICompany,
    location: ILocation,
    device: IDevice,
    shift: ShiftModels.IShift | null,
    productStore: ProductStore,
    currentCompanyStore: CurrentCompanyStore,
    private webSocketStore: WebSocketStore,
  ) {
    super(company, device, productStore, currentCompanyStore)

    this.company = company
    this.location = location
    this.currentShift = shift
    this.device = device
    this.productStore = productStore
    ;(this.currentPayment = new CreatePayment(
      this.company.id,
      this.location.id,
      shift ? shift.id : undefined,
      undefined,
    )),
      (this.onlineOrdersSet = new Set<number>())
    this.lastUpdatedPaymentQueues = moment().utc()

    LocalStorage.setPaymentOption(
      PaymentTypeIds.loyalty,
      this.currentCompanyStore.company?.permissions.canAccessLoyalty ?? false,
    )

    this.subscribeToPaymentQueueWebSocket()
  }

  @observable
  currentPayment: CreatePayment

  @action
  async setCurrentPaymentFromQueues(queue: IPaymentQueueWithExternalSource) {
    try {
      await API.assignDeviceToPaymentQueue(this.company.id, this.location.id, queue.id)

      const newCurrentPayment = await this.setNewCurrentPayment(queue)
      await newCurrentPayment.populateFromQueue(queue, this.POSListings.allNested)

      const isExternalOrder = !!queue.externalSourceName

      if (queue.completedPayment || isExternalOrder) {
        this.currentPayment = newCurrentPayment
        return
      }

      const paymentWithValidatedDiscounts = this.productStore.removeInvalidDiscountsFromUnpaidOrder(
        newCurrentPayment,
        queue,
      )

      this.currentPayment = paymentWithValidatedDiscounts
    } catch (error) {
      this.setNewCurrentPayment()
      message.error(
        parseErrorMsg(error) || 'There was an error populating your cart using the sales queue',
      )
    }
  }

  @observable
  selectedPaymentQueue?: IPaymentQueueWithExternalSource

  @action
  setSelectedPaymentQueue(queue?: IPaymentQueueWithExternalSource) {
    this.selectedPaymentQueue = queue
  }

  @action
  setCurrentPaidPayment(paidPayment?: IPayment) {
    this.paidPayment = paidPayment
  }

  @observable
  private mappedPaymentQueues = new Map<number, IPaymentQueueWithExternalSource>()

  @computed
  get paymentQueues() {
    return [...this.mappedPaymentQueues.values()]
  }

  subscribeToPaymentQueueWebSocket = () => {
    this.webSocketStore.webSocket?.addSubscriptionOnOpen(EventTopics.PAYMENT_QUEUE, async () => {
      const paymentQueues = await API.getPaymentQueues(this.company.id, this.location.id)

      this.setPaymentQueues({ paymentQueues, refreshPaymentQueue: true })

      this.webSocketStore.webSocket?.sendMessage({
        action: RouteKeys.SUBSCRIBE,
        topic: EventTopics.PAYMENT_QUEUE,
        data: {
          tenantId: this.company.id.toString(),
          retailerId: this.location.id.toString(),
        },
      })

      this.webSocketStore.webSocket?.topicListener.addListener<PaymentQueueEventDataContract>(
        EventTopics.PAYMENT_QUEUE,
        CrudEventTypes.CREATED,
        (message) => {
          this.setPaymentQueues({
            paymentQueues: [message.data],
            showNotification: true,
            playSound: true,
          })
        },
      )

      this.webSocketStore.webSocket?.topicListener.addListener<PaymentQueueEventDataContract>(
        EventTopics.PAYMENT_QUEUE,
        CrudEventTypes.UPDATED,
        (message) => {
          if (
            [PaymentQueueStatus.COMPLETED, PaymentQueueStatus.CANCELLED].includes(
              message.data.status,
            )
          ) {
            this.unsetPaymentQueue(message.data.id)
          } else {
            this.setPaymentQueues({
              paymentQueues: [message.data],
              showNotification: true,
              playSound: true,
            })
          }
        },
      )
    })
  }

  @action
  unsetPaymentQueue = (queueId: number) => {
    this.mappedPaymentQueues.delete(queueId)
  }

  @action
  setPaymentQueues = async ({
    paymentQueues,
    refreshPaymentQueue,
    showNotification,
    playSound,
  }: {
    paymentQueues?: IPaymentQueueWithExternalSource[]
    refreshPaymentQueue?: boolean
    showNotification?: boolean
    playSound?: boolean
  } = {}) => {
    const isListeningForCreatedPQ = !!this.webSocketStore.webSocket?.isListening(
      EventTopics.PAYMENT_QUEUE,
      CrudEventTypes.CREATED,
    )
    const isListeningForUpdatedPQ = !!this.webSocketStore.webSocket?.isListening(
      EventTopics.PAYMENT_QUEUE,
      CrudEventTypes.UPDATED,
    )
    let shouldRefresh = refreshPaymentQueue

    if (!paymentQueues) {
      if (isListeningForCreatedPQ && isListeningForUpdatedPQ) return

      paymentQueues = await API.getPaymentQueues(this.company.id, this.location.id)

      shouldRefresh = true
    }

    if (shouldRefresh) {
      this.mappedPaymentQueues.clear()
    }

    paymentQueues.forEach((paymentQueue) =>
      this.mappedPaymentQueues.set(paymentQueue.id, paymentQueue),
    )

    this.processNewOnlineOrders({ paymentQueues, showNotification, playSound })
  }

  @action
  private processNewOnlineOrders = ({
    paymentQueues,
    showNotification,
    playSound,
  }: {
    paymentQueues: IPaymentQueueWithExternalSource[]
    showNotification?: boolean
    playSound?: boolean
  }) => {
    const newOnlineOrders = this.getNewOnlineOrdersInList(paymentQueues)
    const newOnlineOrdersExist = newOnlineOrders.length > 0

    const newOnlineOrdersSet = new Set<number>()
    this.filteredOnlineOrders.forEach((pq) => {
      newOnlineOrdersSet.add(pq.id)
    })

    this.onlineOrdersSet = newOnlineOrdersSet
    this.lastUpdatedPaymentQueues = moment().utc()

    if (newOnlineOrdersExist) {
      const { orderType } = newOnlineOrders[newOnlineOrders.length - 1]

      if (showNotification) {
        message.info(`New unpaid online order for ${orderType}`)
      }

      if (playSound) {
        const sound = new Audio(notificationSound)
        sound.autoplay = false
        sound.play()
      }
    }
  }

  @action
  setNewCurrentPayment = async (queue?: IPaymentQueueWithExternalSource) => {
    const isPaid = !!queue && !!queue.completedPayment
    const newCurrentPayment = new CreatePayment(
      this.company.id,
      this.location.id,
      this.currentShift!.id,
      LocalStorage.getDefaultPaymentStatus(),
    )

    const { canUseExternalLoyalty, canUseSalesChannel } = this.currentCompanyStore
    const canAccessLoyalty = !!this.currentCompanyStore.company?.permissions.canAccessLoyalty

    const discounts = await this.productStore.getPOSDiscounts({
      searchFilter: {
        locationIds: [this.location.id],
      },
      permissions: {
        canUseExternalLoyalty,
        canAccessLoyalty,
      },
      canUseSalesChannel,
    })

    this.productStore.getCategories()

    if (!isPaid) {
      newCurrentPayment.setDiscounts(discounts || [])
    }
    newCurrentPayment.setCategories(this.productStore.categories || [])

    this.currentPayment = newCurrentPayment

    this.setPaymentQueues()

    return newCurrentPayment
  }

  @action
  async addPaymentQueue(createPayment: CreatePayment) {
    const currentPaymentQueue = await API.createPaymentQueue(
      this.company.id,
      this.location.id,
      createPayment,
    )
    this.setNewCurrentPayment()
    return currentPaymentQueue
  }

  @action
  async deletePaymentQueue(queueId: number) {
    try {
      await API.deletePaymentQueue(this.company.id, this.location.id, queueId)

      this.unsetPaymentQueue(queueId)
    } catch (error) {
      message.error(parseErrorMsg(error) || 'There was an error removing your parked queue')
    }
  }

  @action
  async updatePaymentQueue(queueId: number, updatePayment: CreatePayment) {
    await API.updatePaymentQueue(this.company.id, this.location.id, queueId, updatePayment)
    this.setNewCurrentPayment()
  }

  @observable
  currentShift?: ShiftModels.IShift | null

  @observable currentUserPermissions?: UserPermission[]

  @observable
  devicePreferencesArray?: IUserPreference[]

  @computed
  get devicePreferencesObject(): {
    customerWarning: {
      preferenceId: number | null
      booleanValue: boolean | null
    }
  } {
    if (this.devicePreferencesArray) {
      const customerWarning = this.devicePreferencesArray.find(
        (d) => d.preference.name === 'Show warning if no customer is attached to sale',
      )!
      return {
        customerWarning: {
          preferenceId: customerWarning.preferenceId,
          booleanValue: customerWarning.booleanValue,
        },
      }
    } else {
      return {
        customerWarning: { preferenceId: null, booleanValue: null },
      }
    }
  }

  @action
  getCurrentDevice() {
    const deviceAuthToken = window.localStorage.getItem('deviceAuthToken')
    if (!deviceAuthToken) {
      return Promise.resolve()
    }

    setRootAuthToken(deviceAuthToken)

    return API.getCurrentDevice()
      .then((device) => {
        this.device = device
        API.getLocationById(device.companyId, device.locationId).then((location) => {
          this.location = location
        })

        this.getDevicePreferences()
        return device
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          console.log('Invalid deviceAuthToken. Removing from localStorage.')
          window.localStorage.removeItem('deviceAuthToken')
        }
      })
  }

  @action
  logout() {
    API.logout()
    window.localStorage.removeItem('deviceAuthToken')
    Track.clear()
    window.location.href = '/'
  }

  @computed
  get employeeHourStatus(): IEmployeeWithTrackingStatus[] | undefined {
    const { currentShift } = this
    if (!currentShift) {
      return undefined
    } else {
      const allEmployees = currentShift.employeeHours.map((employeeHour) => {
        return {
          employeeId: employeeHour.employeeId,
          employeeName: employeeHour.employeeName,
        }
      })
      const employees: IBasicEmployee[] = uniq(
        allEmployees.map((employee) => JSON.stringify(employee)),
      ).map((employeeObjectString: string) => JSON.parse(employeeObjectString))
      const employeeStatuses = employees.map((employee) => {
        return {
          employeeId: employee.employeeId,
          employeeName: employee.employeeName,
          tracking:
            currentShift.employeeHours.find(
              (employeeHour) =>
                employeeHour.employeeId === employee.employeeId && employeeHour.endTime === null,
            ) !== undefined,
        }
      })
      return employeeStatuses
    }
  }

  @action
  getCurrentShift() {
    return ShiftApi.getCurrentShift(this.company.id, this.device.id).then((shift) => {
      Track.clear()
      this.currentShift = shift
      if (shift) {
        if (shift.currentUserId) {
          Track.identify(shift.currentUserId, {
            id: shift.currentUserId,
            name: shift?.currentUserName,
          })
          this.getEmployeePermissions()
        }
      }
      return shift
    })
  }

  getPaymentById(paymentId: string) {
    return API.getPaymentById(this.company.id, paymentId).then((payment) => {
      return payment
    })
  }

  @action
  createShift(createObject: ShiftModels.ICreateShift) {
    return ShiftApi.addShift(this.company.id, this.device.id, createObject).then((shift) => {
      window.location.reload()
    })
  }

  @action
  endShift(endObject: ShiftModels.IEndShift) {
    return ShiftApi.endShift(this.company.id, this.device.id, endObject)
  }

  @action
  trackHoursWithPasscode(registerPasscode: string) {
    return ShiftApi.trackHoursWithPasscode(this.company.id, this.device.id, registerPasscode).then(
      (user) => {
        this.getCurrentShift()
        message.success(`Clocked in as ${user.name}`)
        return user
      },
    )
  }

  @action
  clearCurrentUser() {
    return ShiftApi.clearCurrentShiftUser(this.company.id, this.currentShift!.id).then((shift) => {
      this.currentShift = shift
      return shift
    })
  }

  @action
  getEmployeePermissions() {
    if (this.currentShift && this.currentShift.currentUserId) {
      return API.getMyPermissions(this.currentShift.currentUserId).then((userPermissions) => {
        this.currentUserPermissions = userPermissions
        return userPermissions
      })
    }
  }

  @action
  clockOut(registerPasscode: string) {
    return ShiftApi.stopTrackingHours(this.company.id, this.device.id, registerPasscode).then(
      (response) => {
        message.error(`Clocked out`)
        return this.getCurrentShift()
      },
    )
  }

  @action
  startBreak(registerPasscode: string) {
    return ShiftApi.startBreakWithPasscode(this.company.id, this.device.id, registerPasscode).then(
      (response) => {
        message.warning(`Started break`)
        return this.getCurrentShift()
      },
    )
  }

  @action
  getDevicePreferences() {
    return API.getDevicePreferences().then((userPreferences) => {
      this.devicePreferencesArray = userPreferences
      return userPreferences
    })
  }

  @action
  updateDevicePreference(preferenceId: number, updateObject: IUserPreferenceUpdate) {
    return API.updateDevicePreference(
      preferenceId,
      updateObject.booleanValue,
      updateObject.stringValue,
    ).then(() => {
      this.getDevicePreferences()
    })
  }

  @action
  setWorldpayTransactionResponse = (
    response?: WorldpayTransactionModels.Internal.ITransactionResponseContract,
  ) => {
    this.worldpayTransactionResponse = response
  }

  hasPermissionAtEntity(permissionName: PermissionNames, entityId: number) {
    if (!this.currentUserPermissions) {
      return false
    } else if (entityId === undefined) {
      const foundPermission = this.currentUserPermissions.find(
        (userPermission) => userPermission.permission.name === permissionName,
      )
      if (foundPermission !== undefined) {
        return true
      } else {
        return false
      }
    } else {
      const foundPermission = this.currentUserPermissions.find(
        (userPermission) =>
          userPermission.permission.name === permissionName &&
          (userPermission.entityId === entityId || userPermission.parentEntityId === entityId),
      )
      if (foundPermission !== undefined) {
        return true
      } else {
        return false
      }
    }
  }

  private processMerrcoTransaction = async (transaction: ITransaction): Promise<ITransaction> => {
    const { paymentId, id: transactionId, amount } = transaction

    if (amount > 0) {
      const saleTransaction = await API.completeTransaction(
        this.company.id,
        this.device.locationId,
        paymentId,
        transactionId,
        { terminalInvoiceId: null, metadata: null },
      )

      return saleTransaction
    } else {
      const refundTransaction = await API.refundTransaction(
        this.company.id,
        this.device.locationId,
        paymentId,
        transactionId,
        { terminalInvoiceId: null, metadata: null },
      )

      return refundTransaction
    }
  }

  private processWorldpayTransaction = async (
    transaction: ITransaction,
    selectedWorldpayTerminal?: WorldpayLaneModels.Internal.ITerminalSetupResponseContract,
    originalPayment?: IPayment,
    webSocket?: WebSocketService,
  ): Promise<ITransaction> => {
    if (!selectedWorldpayTerminal) {
      throw new Error('Worldpay terminal is not selected. Please select a terminal in settings.')
    }

    const worldpayTransaction = new CreateTransaction(transaction.paymentTypeId)

    worldpayTransaction.setAmount(transaction.amount)

    /**
     * `refundTerminalInvoiceId` only exists in CreateTransaction type but webPOS uses ITransaction type
     */
    if ('refundTerminalInvoiceId' in transaction) {
      const refundTerminalInvoiceId = (transaction as any).refundTerminalInvoiceId

      worldpayTransaction.setRefundTerminalInvoiceId(refundTerminalInvoiceId)
    }

    const worldpayProcessTransaction = new WorldpayTransactionModels.WorldpayProcessTransaction(
      {
        device: this.device,
        payment: this.currentPayment,
        transaction: worldpayTransaction,
        laneId: selectedWorldpayTerminal.laneId,
        logger: Track.track,
        errorTracking: Track.track,
        originalPayment,
      },
      webSocket,
    )

    // Amount is in dollars for Worldpay
    const response = await worldpayProcessTransaction.processTransaction()

    if (response && WorldpayTransactionModels.Helper.shouldPrintReceipt(response.data)) {
      const data = response.data as WorldpayTransactionModels.Internal.ITransactionResponseContract

      this.setWorldpayTransactionResponse(data)
    } else {
      this.setWorldpayTransactionResponse()
    }

    const validatedResponse = WorldpayTransactionModels.Helper.validateTransaction(response)

    const isApproved = 'isApproved' in validatedResponse.data
    const isSale =
      'transactionType' in validatedResponse.data &&
      validatedResponse.data.transactionType ===
        WorldpayTransactionModels.Enums.TransactionTypes.SALE
    // Although the reimburse API is used for refunds, the transactionType in response is still a refund
    // TODO: Add a check for the void, reverse and return
    const isRefund =
      'transactionType' in validatedResponse.data &&
      validatedResponse.data.transactionType ===
        WorldpayTransactionModels.Enums.TransactionTypes.REFUND

    let worldpayResponse:
      | WorldpayTransactionModels.Internal.ITransactionResponseContract
      | undefined

    if (isApproved) {
      worldpayResponse =
        validatedResponse.data as WorldpayTransactionModels.Internal.ITransactionResponseContract
    }

    if (worldpayResponse && isSale) {
      return this.processFullOrPartialWorldpayTransaction({
        transaction,
        worldpayTransaction,
        worldpayResponse,
      })
    }

    if (worldpayResponse && isRefund) {
      const { wpTransactionNumber } = worldpayResponse
      const authNumber = wpTransactionNumber || null
      const metadata = JSON.stringify(worldpayResponse)

      return await this.onSuccessfulTerminalTransaction({ transaction, authNumber, metadata })
    }

    Track.track('pos:process-payment:processWorldpayTransaction', worldpayResponse)
    throw new Error(`Worldpay response doesn't exist`)
  }

  private processFullOrPartialWorldpayTransaction = async ({
    transaction,
    worldpayTransaction,
    worldpayResponse,
  }: {
    transaction: ITransaction
    worldpayTransaction: CreateTransaction
    worldpayResponse: WorldpayTransactionModels.Internal.ITransactionResponseContract
  }) => {
    // Approved amount is in dollars for Worldpay
    const { wpTransactionNumber, _processor } = worldpayResponse

    const authNumber = wpTransactionNumber || null
    const stringifiedData = JSON.stringify(worldpayResponse)
    const isFullApproval =
      _processor.expressResponseCode === WorldpayGlobalModels.Enums.ExpressResponseCodes.APPROVED

    if (isFullApproval) {
      Track.track('pos:process-payment:worldpay_full_transaction_completed', {
        transaction: worldpayTransaction,
      })

      return this.onSuccessfulTerminalTransaction({
        transaction,
        authNumber,
        metadata: stringifiedData,
      })
    } else {
      return this.handleWorldpayPartialTransaction({
        transaction,
        worldpayResponse,
      })
    }
  }

  private handleWorldpayPartialTransaction = async ({
    transaction,
    worldpayResponse,
  }: {
    transaction: ITransaction
    worldpayResponse: WorldpayTransactionModels.Internal.ITransactionResponseContract
  }) => {
    const { wpTransactionNumber, approvedAmount } = worldpayResponse

    const authNumber = wpTransactionNumber || null
    const metadata = JSON.stringify(worldpayResponse)

    const approvedAmountInCents = Number(((approvedAmount || 0) * 100).toFixed(0))

    const completedPartialTransaction = new CreateTransaction(PaymentTypeIds.worldpay)
    completedPartialTransaction.setAmount(approvedAmountInCents)
    completedPartialTransaction.setStatus(TransactionStatus.COMPLETED)
    completedPartialTransaction.setTerminalInvoiceId(authNumber)
    completedPartialTransaction.setMetadata(metadata)

    const newTransaction = await API.handlePartialApprovalTransaction(
      this.company.id,
      this.device.locationId,
      transaction.paymentId,
      transaction.id,
      [completedPartialTransaction],
    )

    return newTransaction
  }

  onSuccessfulTerminalTransaction = async ({
    transaction,
    authNumber,
    metadata,
  }: {
    transaction: ITransaction
    authNumber: string | null
    metadata: string
  }): Promise<ITransaction> => {
    const response = await API.completeTransaction(
      this.company.id,
      this.device.locationId,
      transaction.paymentId,
      transaction.id,
      {
        terminalInvoiceId: authNumber,
        metadata,
      },
    )

    Track.track('pos:process-payment:onSuccessfulTerminalTransaction', response)

    return response
  }

  processTransaction = async (
    transaction: ITransaction,
    paymentServiceStore: PaymentServiceStore,
    selectedWorldpayTerminal?: WorldpayLaneModels.Internal.ITerminalSetupResponseContract,
    originalPayment?: IPayment,
    webSocket?: WebSocketService,
  ) => {
    const provider = BlazepayPaymentModels.getProvider(transaction.paymentTypeId)

    const shouldProcessByPaymentService = isEcomPaymentServiceTransaction(transaction)

    if (provider || shouldProcessByPaymentService) {
      const newPayload = provider
        ? { provider, transaction }
        : await correctForEcomPaymentServicePayment({ transaction, paymentServiceStore })

      return paymentServiceStore.processTransaction(
        this.currentPayment,
        newPayload.provider,
        newPayload.transaction,
        this.currentShift,
      )
    }

    if (transaction.paymentTypeId === PaymentTypeIds.merrco) {
      return this.processMerrcoTransaction(transaction)
    }

    if (isWorldpayTransaction(transaction)) {
      return this.processWorldpayTransaction(
        transaction,
        selectedWorldpayTerminal,
        originalPayment,
        webSocket,
      )
    }

    // Other payment types - cash, credit, etc will be returning original transaction
    return transaction
  }

  @action
  addPayment(payment: ICreatePayment) {
    return API.addPayment(this.company.id, this.device.locationId, payment).then(
      (createdPayment) => {
        this.setPOSListing()
        this.getCurrentShift()

        if (this.useSearchBar) {
          this.POSListingsWithFilters.categoryFilterOption.setCategoryIds([])
          this.applyFilters()
        } else {
          this.categoryId = undefined
        }

        return createdPayment
      },
    )
  }

  private filterOnlineOrders = (paymentQueues: IPaymentQueueWithExternalSource[]) => {
    return paymentQueues.filter((paymentQueue) => {
      const isCurrentlyTheActivePayment = paymentQueue.id === this.currentPayment?.paymentQueueId
      const isAssignedToAnotherDevice =
        paymentQueue.deviceId !== null && paymentQueue.deviceId !== this.device?.id
      const isOnlineOrder = paymentQueue.externalSourceName

      return !isCurrentlyTheActivePayment && !isAssignedToAnotherDevice && isOnlineOrder
    })
  }

  @computed
  get filteredOnlineOrders(): IPaymentQueueWithExternalSource[] {
    return this.filterOnlineOrders(this.paymentQueues)
  }

  @computed
  get filteredPaymentQueues(): IPaymentQueueWithExternalSource[] {
    return this.paymentQueues.filter((paymentQueue) => {
      const isCurrentlyTheActivePayment = paymentQueue.id === this.currentPayment?.paymentQueueId
      const isAssignedToAnotherDevice =
        paymentQueue.deviceId !== null && paymentQueue.deviceId !== this.device?.id

      return !isCurrentlyTheActivePayment && !isAssignedToAnotherDevice
    })
  }

  isPaymentLineNegativeInventorySale = ({
    productId,
    batchId,
    inventorySubtracted,
  }: {
    productId: string | null
    batchId: number | null
    inventorySubtracted: number
  }): boolean => {
    if (!productId) {
      return false
    }

    const posListing = this.POSListings.getByProductId(productId)

    if (!posListing) return false

    const batches = this.POSListings.products.batches.getAllPerProduct(productId)

    return isPaymentLineNegativeInventorySale({
      ...posListing,
      batchId,
      inventorySubtracted,
      batches,
    })
  }

  paymentLineNegativeInventorySaleMessage = ({
    productId,
    batchId,
  }: {
    productId: string | null
    batchId: number | null
  }): string | null => {
    if (!productId) {
      return null
    }

    const posListing = this.POSListings.getByProductId(productId)

    if (!posListing) return null

    const batches = this.POSListings.products.batches.getAllPerProduct(productId)

    return negativeInventorySaleMessage(
      {
        ...posListing,
        batches,
      },
      batchId,
    )
  }

  isPaymentNegativeInventorySale = (payment: CreatePayment): boolean => {
    return payment.paymentLines.some((paymentLine) => {
      const { productId, batchId, inventorySubtracted } = paymentLine
      this.isPaymentLineNegativeInventorySale({ productId, batchId, inventorySubtracted })
    })
  }

  isPaymentQueueLineNegativeInventorySale = (
    paymentQueueLine: IPaymentQueueWithExternalSource,
  ): boolean => {
    return paymentQueueLine.paymentLines.some((paymentLine) => {
      if (!paymentLine.productId) {
        return false
      }

      const posListing = this.POSListings.getByProductId(paymentLine.productId)

      if (!posListing) return false

      const batches = this.POSListings.products.batches.getAllPerProduct(paymentLine.productId)

      return isPaymentQueueLineNegativeInventorySale(paymentLine, {
        ...posListing,
        batches,
      })
    })
  }

  getNewOnlineOrdersInList = (
    newPaymentQueues: IPaymentQueueWithExternalSource[],
  ): IPaymentQueueWithExternalSource[] => {
    const newFilteredOnlineOrders = this.filterOnlineOrders(newPaymentQueues)
    return newFilteredOnlineOrders.filter((paymentQueue) => {
      const exists = !!this.onlineOrdersSet.has(paymentQueue.id)
      const createDateUtc = moment.utc(paymentQueue.createDate)
      const isNew = moment(this.lastUpdatedPaymentQueues).diff(createDateUtc) < 0
      const isOnline = paymentQueue.externalSourceName

      return !exists && isNew && isOnline && paymentQueue.status === PaymentQueueStatus.PENDING
    })
  }

  getPaymentQueueSubtotal = (queue: IPaymentQueueWithExternalSource) => {
    const subtotal = queue.paymentLines.reduce((acc, curr) => {
      let price: number
      const isPaid = !!queue && !!queue.completedPayment
      if (curr.productId && !isPaid) {
        const posListing = this.POSListings.getByProductId(curr.productId)

        price = (posListing?.price || 0) * curr.quantity
      } else {
        price = curr.price || 0
      }
      return acc + (price + curr.discountPrice)
    }, 0)

    return Math.round(subtotal)
  }

  @computed
  get queueHasOutOfStockProduct(): boolean {
    return this.filteredPaymentQueues.some((paymentQueue) =>
      this.isPaymentQueueLineNegativeInventorySale(paymentQueue),
    )
  }

  @action
  async cancelPayment(paymentId: string, locationId?: number) {
    try {
      const userId = this.currentShift!.currentUserId!
      await this.currentCompanyStore.cancelPayment(locationId!, paymentId, userId, undefined)
    } catch (error) {
      message.error(parseErrorMsg(error) || 'There was an error cancelling the paid parked sale')
    }
  }

  @action
  async removePaymentQueue(selectedPaymentQueue: IPaymentQueueWithExternalSource) {
    try {
      if (!selectedPaymentQueue.completedPayment) {
        return await this.deletePaymentQueue(selectedPaymentQueue.id)
      }

      const canEditPayments = this.hasPermissionAtEntity(
        PermissionNames.CAN_EDIT_SALES,
        this.location.id,
      )
      const paymentId = selectedPaymentQueue?.completedPayment?.id

      if (canEditPayments && paymentId) {
        await this.cancelPayment(paymentId, selectedPaymentQueue.locationId)
        await this.deletePaymentQueue(selectedPaymentQueue.id)
      } else {
        message.error('You have no permission to cancel a paid parked sale')
      }
    } catch (error) {
      message.error(parseErrorMsg(error) || 'There was an error cancelling a parked sale')
    } finally {
      this.setNewCurrentPayment()
      this.setCurrentPaidPayment(undefined)
      this.setSelectedPaymentQueue(undefined)
    }
  }

  applyFilters() {
    this.POSListingsWithFilters.applyFilters(this.POSListings, this.productStore.posDiscounts || [])
  }

  @computed
  get isCurrentShiftLong(): boolean {
    const shift = this.currentShift
    if (!shift) {
      return false
    } else {
      const shiftStartDate = new Date(shift.createDate)
      const now = Date.now()
      const currentShiftDurationMilliseconds = now - shiftStartDate.getTime()
      return currentShiftDurationMilliseconds > LONG_EMPLOYEE_SHIFT_THRESHOLD
    }
  }

  @computed
  get filteredFlatCategories(): CategoriesModels.IBaseCategory[] {
    return (
      this.POSListings.refData.categories?.filter(
        (category) => category.categoryType !== CategoriesModels.CategoryType.SYSTEM_FEES,
      ) ?? []
    )
  }

  @action
  convertUnpaidQueueToPayment = async (
    queue: IPaymentQueueWithExternalSource,
  ): Promise<CreatePayment> => {
    const companyId = this.company.id
    const locationId = this.location.id

    const newPayment = new CreatePayment(companyId, locationId)

    const categories = await this.productStore.getCategories()
    newPayment.setCategories(categories || [])

    const { canUseExternalLoyalty, canUseSalesChannel } = this.currentCompanyStore
    const canAccessLoyalty = !!this.currentCompanyStore.company?.permissions.canAccessLoyalty

    const discounts = await this.productStore.getPOSDiscounts({
      searchFilter: {
        locationIds: [this.location.id],
      },
      permissions: {
        canUseExternalLoyalty,
        canAccessLoyalty,
      },
      canUseSalesChannel,
    })

    newPayment.setDiscounts(discounts || [])

    await newPayment.populateFromQueue(queue, this.POSListings.allNested)

    const isExternalOrder = !!queue.externalSourceName

    if (queue.completedPayment || isExternalOrder) {
      return newPayment
    }

    const paymentWithValidatedDiscounts = this.productStore.removeInvalidDiscountsFromUnpaidOrder(
      newPayment,
      queue,
    )

    return paymentWithValidatedDiscounts
  }
}
