import React, { useState } from 'react'
import { Dropdown, Menu, message } from 'antd-v4'
import { GlobalProductsHeader } from './GlobalCatalogHeader'
import { CompaniesModels } from '@getgreenline/companies'
import { axios } from '@getgreenline/homi-shared'
import Endpoints from '../../../constants/Endpoints'
import { downloadCSV } from '../../../utilities/helpers'

export function GlobalCatalogExport() {
  const [isLoading, setIsLoading] = useState(false)
  const [province, setProvince] = useState<CompaniesModels.Province | undefined>()

  const exportCSV = async () => {
    if (!province) return message.error('Please select a province')

    setIsLoading(true)

    try {
      const presignedUrlResponse = await axios.get(
        Endpoints.GET_GLOBAL_PRODUCTS_DOWNLOAD_SIGNED_URL(province),
      )
      const { signedUrl } = presignedUrlResponse.data
      const encodedfileName = new URL(signedUrl).pathname.split('/').pop()
      if (!encodedfileName) throw new Error('Invalid URL')
      const fileName = decodeURIComponent(encodedfileName)

      const response = await axios.get(signedUrl, {
        transformRequest: (data, headers) => {
          delete headers.common['Authorization']
          return data
        },
      })

      downloadCSV(fileName, response.data)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <>
      <GlobalProductsHeader />
      <div className='province-products-export'>
        <Dropdown.Button
          size='large'
          trigger={['click']}
          placement='bottomCenter'
          disabled={isLoading}
          onClick={exportCSV}
          overlay={
            <Menu
              style={{ height: 230, overflowY: 'scroll' }}
              onClick={(e) => setProvince(e.key as CompaniesModels.Province)}
            >
              <Menu.Item key={CompaniesModels.Province.AB}>{CompaniesModels.Province.AB}</Menu.Item>
              <Menu.Item key={CompaniesModels.Province.BC}>{CompaniesModels.Province.BC}</Menu.Item>
              <Menu.Item key={CompaniesModels.Province.ON}>{CompaniesModels.Province.ON}</Menu.Item>
              {Object.keys(CompaniesModels.Province).map((key) => {
                const province: CompaniesModels.Province = CompaniesModels.Province[key]
                const hasCatalogue = [
                  CompaniesModels.Province.AB,
                  CompaniesModels.Province.BC,
                  CompaniesModels.Province.ON,
                ].includes(province)
                if (hasCatalogue) return

                return <Menu.Item key={province}>{province}</Menu.Item>
              })}
            </Menu>
          }
          buttonsRender={([leftButton, rightButton]) => [
            leftButton,
            React.cloneElement(rightButton as any, { loading: isLoading }),
          ]}
        >
          {province ? `Export ${province}` : 'Select province'}
        </Dropdown.Button>
      </div>
    </>
  )
}
