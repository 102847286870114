import { Menu } from 'antd-v4'
import React from 'react'
import { Link } from 'react-router'
import { Icon } from 'antd'
import { Routes } from '../../../constants/Routes'

const { SubMenu } = Menu
const defaultIconStyles = { width: 14, height: 14 }

export const AdminMenu = () => {
  const selectedKeys = window.location.pathname
    .split('/')
    .filter((path, index) => index > 2)
    .join('/')

  return (
    <Menu selectedKeys={[selectedKeys]} mode='horizontal' theme='dark'>
      <Menu.Item key='companies'>
        <Link to={Routes.ADMIN_COMPANIES}>
          <>
            <Icon type='home' style={defaultIconStyles} />
            Companies
          </>
        </Link>
      </Menu.Item>
      <Menu.Item key='integrations'>
        <Link to={Routes.ADMIN_INTEGRATIONS}>
          <>
            <Icon type='appstore' style={defaultIconStyles} />
            Integrations
          </>
        </Link>
      </Menu.Item>
      <Menu.Item key='organizations'>
        <Link to={Routes.ADMIN_ORGANIZATIONS}>
          <>
            <Icon type='apartment' style={defaultIconStyles} />
            Organizations
          </>
        </Link>
      </Menu.Item>
      <Menu.Item key='development'>
        <Link to={Routes.ADMIN_DEVELOPMENT}>
          <>
            <Icon type='setting' style={defaultIconStyles} />
            Development
          </>
        </Link>
      </Menu.Item>
      <SubMenu
        key='provinceProducts'
        title='Province Products'
        icon={<Icon type='shopping' style={defaultIconStyles} />}
      >
        <Menu.Item key='provinceProducts/search'>
          <Link to={Routes.ADMIN_PROVINCE_PRODUCTS_SEARCH}>
            <>
              <Icon type='search' style={defaultIconStyles} />
              Search
            </>
          </Link>
        </Menu.Item>
        <Menu.Item key='provinceProducts/export'>
          <Link to={Routes.ADMIN_PROVINCE_PRODUCTS_EXPORT}>
            <>
              <Icon type='export' style={defaultIconStyles} />
              Export
            </>
          </Link>
        </Menu.Item>
        <Menu.Item key='provinceProducts/import'>
          <Link to={Routes.ADMIN_PROVINCE_PRODUCTS_IMPORT}>
            <>
              <Icon type='import' style={defaultIconStyles} />
              Import
            </>
          </Link>
        </Menu.Item>
      </SubMenu>
      <SubMenu
        key='globalCatalog'
        title='Global Catalog'
        icon={<Icon type='global' style={defaultIconStyles} />}
      >
        <Menu.Item key='globalCatalog/import'>
          <Link to={Routes.ADMIN_GLOBAL_CATALOG_IMPORT}>
            <>
              <Icon type='import' style={defaultIconStyles} />
              Import
            </>
          </Link>
        </Menu.Item>
        <Menu.Item key='globalCatalog/export'>
          <Link to={Routes.ADMIN_GLOBAL_CATALOG_EXPORT}>
            <>
              <Icon type='export' style={defaultIconStyles} />
              Export
            </>
          </Link>
        </Menu.Item>
      </SubMenu>
      <Menu.Item key='register'>
        <Link to={Routes.REGISTER}>
          <>
            <Icon type='setting' style={defaultIconStyles} />
            Register
          </>
        </Link>
      </Menu.Item>
      <Menu.Item key='styleGuidelines'>
        <Link to={Routes.ADMIN_STYLE_GUIDELINES}>
          <>
            <Icon type='setting' style={defaultIconStyles} />
            Style Guidelines
          </>
        </Link>
      </Menu.Item>
      <Menu.Item key='payment-options'>
        <Link to={Routes.ADMIN_PAYMENT_OPTIONS}>
          <>
            <Icon type='setting' style={defaultIconStyles} />
            Payments
          </>
        </Link>
      </Menu.Item>
      <Menu.Item key='compliance-configuration'>
        <Link to={Routes.ADMIN_COMPLIANCE_CONFIG}>
          <>
            <Icon type='setting' style={defaultIconStyles} />
            Compliance Setup
          </>
        </Link>
      </Menu.Item>
      <Menu.Item key='storybook'>
        <Link to={Routes.ADMIN_STORYBOOK}>
          <>
            <Icon type='book' style={defaultIconStyles} />
            Storybook
          </>
        </Link>
      </Menu.Item>
    </Menu>
  )
}
