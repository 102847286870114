import React, { FC } from 'react'
import { SingleSpaParcel } from './SingleSpaParcel'
import { mfeConfigs } from '../../utilities/mfe'
import { inject } from 'mobx-react'
import { observer } from 'mobx-react-lite'
import { CurrentCompanyStore } from '../../stores/CurrentCompanyStore'
import { LocalStorage } from '../../utilities/LocalStorage'
import { useEvents } from '../../utilities/hooks/useEvents'

interface IAdminCompliancePageProps {
  currentCompanyStore: CurrentCompanyStore
}

const _AdminCompliancePage: FC<IAdminCompliancePageProps> = ({ currentCompanyStore }) => {
  useEvents({ currentCompanyStore })

  return (
    <SingleSpaParcel
      basename='/admin/v2/compliance'
      config={mfeConfigs.LOAD_COMPLIANCE_PAGE}
      authToken={LocalStorage.getAuthToken}
      mode='admin'
    />
  )
}

export const AdminCompliancePage = inject('currentCompanyStore')(observer(_AdminCompliancePage))
